import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { StrapiAccess } from '../../api/strapi';
import { useUser } from '../../providers/UserProvider';
import { validateEmail } from '../../utils/validation';
import { AddUserIcon, CloseIcon } from '../../components/svg';
import TextField from '../../components/TextField';
import { useApiQuery } from '../../hooks';
import { ENV } from '../../utils/environment';

type InvitationResultStatus = 'sent' | 'contactDuplicate' | 'accessAlreadyExist' | 'error';

type InvitationResult = {
  email: string;
  status: InvitationResultStatus;
  access: StrapiAccess;
};

type AccountInviteProps = {
  onInvite: (invitedAccesses: StrapiAccess[]) => void;
};

const AccountInvite = ({ onInvite }: AccountInviteProps) => {
  const { fetch, account } = useUser();
  const { doApiQuery } = useApiQuery();

  const [dialogStep, setDialogStep] = useState<'fill' | 'done'>('fill');
  const [inviteDialogOpen, setInviteDialogOpen] = useState<boolean>(false);
  const [invitationsValue, setInvitationsValue] = useState<string>('');
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | null>(null);
  const [invitationResults, setInvitationResults] = useState<InvitationResult[]>([]);

  const openDialog = () => {
    setInvitationsValue('');
    setValidationErrorMessage(null);
    setDialogStep('fill');
    setInviteDialogOpen(true);
  };

  const closeDialog = () => {
    setInviteDialogOpen(false);
    setInvitationsValue('');
    setValidationErrorMessage(null);
  };

  const invitePeople = async () => {
    setValidationErrorMessage(null);

    // 1. validate invitation content
    if (invitationsValue.trim().length === 0) {
      setValidationErrorMessage(`Veuillez renseigner un e-mail.`);
      return;
    }

    const emails = invitationsValue.split(/[,;]/).map((email) => email.trim().toLowerCase());

    const wrongEmails = emails.filter((email) => !validateEmail(email));

    if (wrongEmails.length > 0) {
      setValidationErrorMessage(
        `Les e-mails suivants ne sont pas valides: ${wrongEmails.join(', ')}`,
      );
      return;
    }

    const success = await doApiQuery("l'envoi des invitations", async () => {
      // 2. send to backend
      const response = await fetch(`/api/accounts/${account!.id}/invite`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emails: emails }),
      });

      setDialogStep('done');
      if (!response.ok) {
        return response;
      }

      const results: InvitationResult[] = await response.json();
      setInvitationResults(results);

      if (results.some((invitation) => invitation.status === 'sent')) {
        const invitedAccesses = results
          .filter((invitation) => invitation.status === 'sent' && invitation.access)
          .map((invitation) => invitation.access);
        onInvite(invitedAccesses);
      }
      return response;
    });
    if (!success) {
      setDialogStep('done');
    }
  };

  return (
    <>
      <Button color="primary" className="invite" onClick={openDialog}>
        {AddUserIcon}Inviter un nouvel utilisateur
      </Button>
      <Dialog className="inviteDialog" open={inviteDialogOpen} onClose={closeDialog}>
        {dialogStep === 'fill' && (
          <>
            <DialogTitle>Inviter un utilisateur</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Saisissez ci-dessous les adresses e-mail des utilisateurs que vous souhaitez inviter
                dans votre organisation sur l'espace adhérents du {ENV.CLIENT_NAME}, séparées par
                des virgules (",") ou des points-virgules (",").
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                required
                label="Adresses e-mail"
                fullWidth
                variant="standard"
                onChange={(e) => setInvitationsValue(e.target.value)}
              />
              <div className="error">{validationErrorMessage}</div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={closeDialog}>
                Annuler
              </Button>
              <Button onClick={invitePeople}>Inviter</Button>
            </DialogActions>
          </>
        )}
        {dialogStep === 'done' && (
          <DoneStep closeDialog={closeDialog} invitations={invitationResults} />
        )}
      </Dialog>
    </>
  );
};

type DoneStepProps = {
  invitations: InvitationResult[];
  closeDialog: () => void;
};

const DoneStep = ({ invitations, closeDialog }: DoneStepProps) => {
  const invitationsByStatus = invitations.reduce((acc, item) => {
    const group: InvitationResult[] = acc[item.status] || [];
    group.push(item);
    acc[item.status] = group;
    return acc;
  }, {} as Partial<Record<InvitationResultStatus, InvitationResult[]>>);

  const invitationsSent = Boolean(invitationsByStatus['sent']);

  return (
    <div className={invitationsSent ? 'infoSuccess' : 'infoError'}>
      <Button className="close" onClick={closeDialog}>
        {CloseIcon}
      </Button>
      {invitationsSent ? (
        <DialogTitle>Invitations envoyées !</DialogTitle>
      ) : (
        <DialogTitle>Aucune invitation n'a pu être envoyée</DialogTitle>
      )}
      <DialogContent>
        <InvitationResultList
          text="Les utilisateurs suivants ont été invités :"
          invitations={invitationsByStatus['sent']}
        />
        <InvitationResultList
          text="Les utilisateurs suivants ont déjà été invités :"
          invitations={invitationsByStatus['accessAlreadyExist']}
        />
        <InvitationResultList
          text={`Les utilisateurs suivants n'ont pas pu être invités suite à des raisons techniques, le ${ENV.CLIENT_NAME} reviendra vers vous :`}
          invitations={invitationsByStatus['contactDuplicate']}
        />
      </DialogContent>
    </div>
  );
};

type InvitationResultListProps = {
  text: string;
  invitations?: InvitationResult[];
};

const InvitationResultList = ({ text, invitations }: InvitationResultListProps) => {
  if (!invitations) {
    return null;
  }

  return (
    <div className="invitationResultList">
      <h3>{text}</h3>
      <ul>
        {invitations.map((invitation) => (
          <li className="invitationResultItem">
            <strong>{invitation.email}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountInvite;
