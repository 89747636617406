import { StrapiEvent } from '../../api/strapi';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TagsSection } from '../../components/sections';
import EventDatesSection from '../../components/event/EventDatesSection';
import { computeDates, cover } from '../../utils/events';
import Background from '../../components/Background';
import clsx from 'clsx';

type EventCardProps = {
  event: StrapiEvent;
};

const EventCard = ({ event }: EventCardProps) => {
  const attendance = event.publication.attendances[0];
  const smallFont = event.name.length > 100;

  const { start, end, duration, past } = computeDates(event);

  return (
    <div className={classNames('eventCard', past ? 'past' : 'future')}>
      <div className="cover">
        <Link to={`/evenement/${event.id}`}>
          <Background className="coverImage" file={cover(event)} />
        </Link>
        <div className="coverDate">
          <div className="start">
            <span className="day">{start?.format('DD')}</span>
            <span className="month">{start?.format('MMM')}</span>
          </div>
          {duration && duration > 1 && <div className="duration">{duration} j</div>}
        </div>
        {event.publication.private && <div className="coverPrivate">Sur invitation</div>}
        <div className="coverContent">
          <Link
            className={clsx({
              name: true,
              small: smallFont,
            })}
            to={`/evenement/${event.id}`}
          >
            {event.name}
          </Link>
          <TagsSection tags={event.tags} />
        </div>
      </div>
      <div className="content">
        <div className="infos">
          <ul>
            <EventDatesSection start={start} end={end} />
            {event.address ? <li className="address">{event.address}</li> : <></>}
          </ul>
        </div>
        <div className="contentFooter">
          <div className={classNames('mode', event.publication.mode)}>
            {event.publication.mode === 'onsite' && 'En présentiel'}
            {event.publication.mode === 'remote' && 'En distanciel'}
            {event.publication.mode === 'both' && 'Évènement mixte'}
          </div>
          {past ? (
            <div className="past">Évènement passé</div>
          ) : (
            <Link
              className={classNames('attendance', attendance?.status)}
              to={`/evenement/${event.id}`}
            >
              {attendance?.status === 'participate' && 'Je participe'}
              {attendance?.status === 'participateRemote' && 'Je participe'}
              {attendance?.status === 'dontParticipate' && 'Je ne participe pas'}
              {attendance?.status === 'invited' && "Répondre à l'invitation"}
              {attendance?.status === 'unknown' && "M'inscrire"}
              {attendance?.status === undefined && "M'inscrire"}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
