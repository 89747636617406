import React, { useState } from 'react';
import { Button, Checkbox, Drawer, FormControlLabel } from '@mui/material';
import { EventSearchFilters, EventSearchTimeFilter } from './EventSearchPage';
import TextField from '../../components/TextField';
import { DesktopDatePicker } from '@mui/lab';
import clsx from 'clsx';
import { GrappPublicationMode, StrapiTag } from '../../api/strapi';
import { BackIcon } from '../../components/svg';

type EventSearchFiltersHeaderProps = {
  filters: EventSearchFilters;
  tags?: StrapiTag[];
  onChange: (filters: EventSearchFilters) => void;
};

const EventSearchFiltersHeader = ({ filters, tags, onChange }: EventSearchFiltersHeaderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [editedFilters, setEditedFilters] = useState<EventSearchFilters>(filters);

  const openMenu = () => {
    setEditedFilters(filters);
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const updateTime = (value: EventSearchTimeFilter) => {
    onChange({ ...filters, time: value });
  };

  const toggleTag = (tagId: number) => {
    let newTags = new Set(editedFilters.tags);
    if (newTags.has(tagId)) {
      newTags.delete(tagId);
    } else {
      newTags.add(tagId);
    }
    setEditedFilters({ ...editedFilters, tags: Array.from(newTags) });
  };

  const toggleMode = (mode: GrappPublicationMode) => {
    let newModes = new Set(editedFilters.modes);
    if (newModes.has(mode)) {
      newModes.delete(mode);
    } else {
      newModes.add(mode);
    }
    setEditedFilters({ ...editedFilters, modes: Array.from(newModes) });
  };

  const hasFilters = Boolean(
    filters.tags.length ||
      filters.modes.length ||
      filters.query.length ||
      filters.start ||
      filters.end,
  );

  return (
    <>
      <div className="options">
        <ul className="tabs">
          <li>
            <Button
              className={clsx({ active: filters.time === 'all' })}
              onClick={() => updateTime('all')}
            >
              Tous
            </Button>
          </li>
          <li>
            <Button
              className={clsx({ active: filters.time === 'next' })}
              onClick={() => updateTime('next')}
            >
              Évènements à venir
            </Button>
          </li>
          <li>
            <Button
              className={clsx({ active: filters.time === 'previous' })}
              onClick={() => updateTime('previous')}
            >
              Évènements passés
            </Button>
          </li>
        </ul>
        <div className="actions">
          {hasFilters && (
            <Button
              className="reset"
              onClick={() => {
                onChange({ tags: [], time: filters.time, query: '', modes: [] });
              }}
            >
              Réinitialiser les filtres
            </Button>
          )}
          <Button className="filter" onClick={openMenu}>
            Filtrer
          </Button>
        </div>
      </div>
      <Drawer id="filtersMenu" anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="hider">
          <div className="scroll">
            <div className="content">
              <Button className="close" onClick={closeMenu}>
                {BackIcon}Retour
              </Button>
              <h6>Filtrer</h6>
              <TextField
                placeholder="Rechercher un évènement"
                value={editedFilters.query}
                onChange={(e) => setEditedFilters({ ...editedFilters, query: e.target.value })}
              />
              {tags && tags.length > 1 && (
                <div className="filterFacets">
                  <div className="filterTitle">Par tag</div>
                  <ul>
                    {tags?.map((tag) => (
                      <li key={tag.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => toggleTag(tag.id)}
                              checked={editedFilters.tags.includes(tag.id)}
                            />
                          }
                          label={tag.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="filterDates">
                <div className="filterTitle">Par date</div>
                <DesktopDatePicker
                  label="Depuis le"
                  inputFormat="DD/MM/YYYY"
                  value={editedFilters.start ?? null}
                  onChange={(value) =>
                    setEditedFilters({ ...editedFilters, start: value ?? undefined })
                  }
                  renderInput={(params) => {
                    params.inputProps!.placeholder = 'JJ/MM/AAAA';
                    return <TextField {...params} />;
                  }}
                  PaperProps={{ className: 'datePicker' }}
                />
                <DesktopDatePicker
                  label="Jusqu'au"
                  inputFormat="DD/MM/YYYY"
                  value={editedFilters.end ?? null}
                  onChange={(value) =>
                    setEditedFilters({ ...editedFilters, end: value ?? undefined })
                  }
                  renderInput={(params) => {
                    params.inputProps!.placeholder = 'JJ/MM/AAAA';
                    return <TextField {...params} />;
                  }}
                  PaperProps={{ className: 'datePicker' }}
                />
              </div>
              <div className="filterFacets">
                <div className="filterTitle">Par modalité</div>
                <ul>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => toggleMode('both')}
                          checked={editedFilters.modes.includes('both')}
                        />
                      }
                      label="Mixte"
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => toggleMode('onsite')}
                          checked={editedFilters.modes.includes('onsite')}
                        />
                      }
                      label="Présentiel"
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => toggleMode('remote')}
                          checked={editedFilters.modes.includes('remote')}
                        />
                      }
                      label="Distanciel"
                    />
                  </li>
                </ul>
              </div>
              <div className="actions">
                <Button
                  onClick={() => {
                    onChange(editedFilters);
                    setOpen(false);
                  }}
                >
                  Voir les résultats
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default EventSearchFiltersHeader;
