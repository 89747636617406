import React from 'react';
import { MenuArborescenceIcon } from '../svg';
import {
  SearchResult,
  StrapiEvent,
  StrapiFile,
  StrapiPage,
  StrapiSubpage,
  StrapiTag,
} from '../../api/strapi';

import { computeDates } from '../../utils/events';
import { extractSearchResultInformation } from '../../utils/search';

export function HeaderSearchResultCartouche({ result }: { result: SearchResult }) {
  switch (result.type) {
    case 'file':
      return <HeaderSearchResultDoc item={result.data} parent={result.parent} />;
    case 'page':
      return <HeaderSearchResultPage item={result.data} />;
    case 'subpage':
      return <HeaderSearchResultSubpage item={result.data} />;
    case 'tag':
      return <HeaderSearchResultTag item={result.data} />;
    case 'event':
      return <HeaderSearchResultEvent item={result.data} />;
    default:
      return <HeaderSearchResultDefault item={result} />;
  }
}

type SearchResultProps<T> = {
  item: T;
  parent?: SearchResult;
};

export function HeaderSearchResultDoc({ item, parent }: SearchResultProps<StrapiFile>) {
  const { name, icon } = extractSearchResultInformation(parent)!;
  return (
    <div className="headerSearchResult">
      <div className="title">{item.name}</div>
      <div className="infos">
        <ul>
          <li className="type">Document</li>
          <li className="parent">
            {icon} {name}
          </li>
        </ul>
      </div>
    </div>
  );
}

export function HeaderSearchResultEvent({ item }: SearchResultProps<StrapiEvent>) {
  const { start } = computeDates(item);
  return (
    <div className="headerSearchResult">
      <div className="title">{item.name}</div>
      <div className="infos">
        <ul>
          <li className="type">Évènement</li>
          <li className="date">{start?.format('DD MMMM YYYY')}</li>
          <li className="hour">{start?.format('HH[h]mm')}</li>
        </ul>
      </div>
    </div>
  );
}

export function HeaderSearchResultTag({ item }: SearchResultProps<StrapiTag>) {
  return (
    <div className="headerSearchResult">
      <div className="title">{item.title}</div>
      <div className="infos">
        <ul>
          <li className="type">Adhésion</li>
        </ul>
      </div>
    </div>
  );
}

export function HeaderSearchResultPage({ item }: SearchResultProps<StrapiPage>) {
  return (
    <div className="headerSearchResult">
      <div className="title">{item.title}</div>
      <div className="infos">
        <ul>
          <li className="type">Adhésion</li>
        </ul>
      </div>
    </div>
  );
}

export function HeaderSearchResultSubpage({ item }: SearchResultProps<StrapiSubpage>) {
  return (
    <div className="headerSearchResult">
      <div className="title">{item.title}</div>
      <div className="infos">
        <ul>
          <li className="type">Adhésion</li>
          <li className="parent">
            {MenuArborescenceIcon} {item.parent?.title}
          </li>
        </ul>
      </div>
    </div>
  );
}

export function HeaderSearchResultDefault({ item }: { item: SearchResult }) {
  const data = extractSearchResultInformation(item);

  if (!data) {
    return null;
  }

  return (
    <div className="headerSearchResult">
      <div className="title">{data.name}</div>
      <div className="infos">
        <ul>
          <li className="type">{data.type}</li>
        </ul>
      </div>
    </div>
  );
}
