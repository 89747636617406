import React from 'react';
import { StrapiDate, StrapiPage, StrapiSubscription } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import {
  DocumentsSection,
  EventsSection,
  NewslettersSection,
  PreviewSection,
  ReferralsSection,
  RichTextSection,
  TagsSection,
  UpdateSection,
} from '../../components/sections';
import { Link, useParams } from 'react-router-dom';
import { PreviewPageProps } from '../page-types';
import Error from '../../components/Error';
import { useDesktop, usePageFetch } from '../../hooks';
import dayjs from 'dayjs';
import BackButton from '../../components/BackButton';

const ContentPage = ({ preview }: PreviewPageProps) => {
  const { id } = useParams();
  const desktop = useDesktop();

  const { data, error } = usePageFetch<StrapiPage>(
    `/api/pages/${id}${preview ? '?preview=true' : ''}`,
  );

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      {preview && <PreviewSection />}
      <Header />
      {data && (
        <div id="contentPage">
          <div id="bottom">
            <main>
              <div id="top">
                <BackButton />
                <div className="content">
                  <h1>
                    {data.title}
                    {preview && <span className="preview"> (prévisualisation)</span>}
                  </h1>
                  <TagsSection tags={data.tags} />
                  <UpdateSection entity={data} />
                  {!desktop && <SubscriptionCartouche subscription={data.subscription} />}
                </div>
              </div>
              {!desktop && (
                <ReferralsSection referrals={data.referrals} title="Les interlocuteurs" />
              )}
              <RichTextSection html={data.content} />
              <DocumentsSection documents={data.documents} title="Les documents liés" />
            </main>
            <aside>
              {desktop && (
                <>
                  <SubscriptionCartouche subscription={data.subscription} />
                  <ReferralsSection referrals={data.referrals} title="Les interlocuteurs" />
                </>
              )}
              <EventsSection events={data.events} />
              <NewslettersSection newsletters={data.newsletters} />
              {data.children.length > 0 && (
                <section className="pages">
                  <h2>Pages affiliées</h2>
                  <div className="pages">
                    <ul>
                      {data.children.map((child) => (
                        <li key={child.id}>
                          <Link to={`/sous-page/${child.id}`}>{child.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </section>
              )}
            </aside>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

const SubscriptionCartouche = ({ subscription }: { subscription: StrapiSubscription | null }) => {
  const accountSubscription = subscription?.accountSubscriptions[0];

  let date: StrapiDate | undefined;
  switch (subscription?.startReference) {
    case 'startDate':
      date = accountSubscription?.start;
      break;
    case 'deliberationDate':
      date = accountSubscription?.deliberation;
      break;
    case 'notificationDate':
      date = accountSubscription?.notification;
      break;
  }

  if (date) {
    return (
      <div className="subscribed">
        Mon organisation bénéficie de cette offre depuis le {dayjs(date).format('DD/MM/YYYY')}
      </div>
    );
  } else {
    return <div className="subscribed">Mon organisation bénéficie de cette offre</div>;
  }
};

export default ContentPage;
