import { createTheme } from '@mui/material';
import { frFR } from '@mui/material/locale';
import { frFR as frFRGrid } from '@mui/x-data-grid';

export const defaultTheme = createTheme(
  {
    palette: {
      primary: {
        contrastText: '#fff',
        dark: '#388e3c',
        main: '#639f65',
        light: '#b9f6ca',
      },
    },
  },
  frFR,
  frFRGrid,
);
