import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { StrapiNewsletter } from '../../api/strapi';
import { CartoucheNewsletter } from '../../components/cartouches';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';

type HomeNewslettersSectionProps = {
  newsletters: StrapiNewsletter[];
};

const Stepper = ({
  stepCount,
  onStepChange,
}: {
  stepCount: number;
  onStepChange: (step: number) => void;
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    if (activeStep < stepCount - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  useEffect(() => {
    onStepChange(activeStep);
  }, [activeStep, onStepChange]);

  if (stepCount < 2) {
    return null;
  }
  return (
    <MobileStepper
      variant="dots"
      steps={stepCount}
      position="static"
      activeStep={activeStep}
      backButton={
        <Button
          className={clsx({
            stepperButton: true,
            disabled: activeStep === 0,
          })}
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          <KeyboardArrowLeft />
        </Button>
      }
      nextButton={
        <Button
          className={clsx({
            stepperButton: true,
            disabled: activeStep === stepCount - 1,
          })}
          onClick={handleNext}
          disabled={activeStep === stepCount - 1}
        >
          <KeyboardArrowRight />
        </Button>
      }
    />
  );
};

const HomeNewslettersSection = ({ newsletters }: HomeNewslettersSectionProps) => {
  const stepCount = Math.max(1, Math.ceil(newsletters.length / 5));
  const [currentNewsletters, setCurrentNewsletters] = useState(newsletters.slice(0, 5));

  const onStepChange = useCallback(
    (step) => {
      setCurrentNewsletters(newsletters.slice(5 * step, 5 * step + 5));
    },
    [newsletters],
  );

  return (
    <section className="newsletters">
      <h2>Mes actualités</h2>

      <Stepper stepCount={stepCount} onStepChange={onStepChange} />
      <ul className="list">
        {currentNewsletters.map((newsletter) => (
          <li className="item" key={newsletter.id}>
            <CartoucheNewsletter newsletter={newsletter} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default HomeNewslettersSection;
