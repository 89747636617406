import { useUser } from '../../providers/UserProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ExpandMoreIcon } from '../svg';
import qs from 'qs';
import { GrappAccount, UserAccount } from '../../api/strapi';
import { formatUser } from '../../api/format';
import { Link } from 'react-router-dom';
import TextField from '../TextField';
import { useDebounce } from '../../hooks';
import { useErrorHandler } from 'react-error-boundary';
import { ENV } from '../../utils/environment';

export const UserMenu = () => {
  const { user, account, fetch, selectAccount, logout } = useUser();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const open = Boolean(menuAnchor);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const [submenuAnchor, setSubmenuAnchor] = useState<null | HTMLElement>(null);
  const submenuOpen = Boolean(submenuAnchor);
  const openSubmenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccounts(defaultAccounts);
    setSubmenuAnchor(event.currentTarget);
  };
  const closeSubmenu = () => {
    setSubmenuAnchor(null);
  };

  const defaultAccounts = useMemo(() => user?.accounts ?? [], [user]);
  const [accounts, setAccounts] = useState<UserAccount[]>(defaultAccounts);
  const [accountFilter, setAccountFilter] = useState<string>('');
  const debouncedFilter = useDebounce(accountFilter, 200);
  const handleError = useErrorHandler();

  useEffect(() => {
    const call = async () => {
      if (debouncedFilter.length > 0) {
        const query = qs.stringify({
          query: debouncedFilter,
        });

        const response = await fetch(`/api/accounts?${query}`);
        const results: GrappAccount[] = await response.json();
        setAccounts(
          results.map((ga) => ({
            id: ga.id,
            name: ga.name,
            jobTitle: `Agent ${ENV.CLIENT_NAME}`,
            admin: true,
          })),
        );
      } else {
        setAccounts(defaultAccounts);
      }
    };
    call().catch(handleError);
  }, [debouncedFilter, defaultAccounts, fetch, handleError]);

  if (!user) {
    return <div className="user" />;
  }

  const canSwitchAccount = user.fullAccess || user.accounts.length > 1;

  return (
    <div className="userWidget">
      <Button onClick={openMenu}>
        <div className="logo" />
        <span className="name">{formatUser(user)}</span>
        <span className="account" title={account?.name}>
          {account?.name}
        </span>
        {ExpandMoreIcon}
      </Button>
      <Menu className="userMenu" anchorEl={menuAnchor} open={open} onClose={closeMenu}>
        <MenuItem className="parameters">
          <Link to="/profil/parametres">Mes paramètres</Link>
        </MenuItem>
        {canSwitchAccount && (
          <MenuItem className="switch">
            <Button onClick={openSubmenu}>Changer d'organisation {ExpandMoreIcon}</Button>
            <Menu
              className="organisationSwitch"
              anchorEl={submenuAnchor}
              open={submenuOpen}
              onClose={closeSubmenu}
            >
              {user.fullAccess && (
                <TextField
                  onChange={(e) => setAccountFilter(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                  placeholder="Recherchez une organisation"
                />
              )}
              {accounts.map((item) => (
                <MenuItem key={item.id}>
                  <button
                    onClick={() => {
                      selectAccount(item);
                      closeMenu();
                      closeSubmenu();
                    }}
                  >
                    {item.name}
                  </button>
                </MenuItem>
              ))}
            </Menu>
          </MenuItem>
        )}
        <MenuItem className="organisation">
          <Link to="/organisation/informations">Mon organisation</Link>
        </MenuItem>
        {account?.admin && (
          <MenuItem className="access">
            <Link to="/organisation/acces">Gestion des accès</Link>
          </MenuItem>
        )}
        <MenuItem className="disconnect" onClick={() => logout()}>
          <button>Déconnexion</button>
        </MenuItem>
      </Menu>
    </div>
  );
};
