import { useNavigate } from 'react-router-dom';
import { BackIcon } from './svg';
import clsx from 'clsx';

type BackButtonProps = {
  // light or dark refers to the background theme
  variant?: 'light' | 'dark';
};

const BackButton = ({ variant }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className={clsx({
        navigationBack: true,
        dark: variant === 'dark',
      })}
      onClick={() => navigate(-1)}
    >
      {BackIcon}Retour
    </button>
  );
};

export default BackButton;
