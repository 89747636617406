import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { GrappPublicationMode, StrapiEvent } from '../../api/strapi';
import dayjs from 'dayjs';
import { ShareIcon } from '../../components/svg';
import { useDesktop } from '../../hooks';
import { prependHttp } from '../../utils/utils';
import { ENV } from '../../utils/environment';

const LABELS_BY_MODE: Record<GrappPublicationMode, string> = {
  both: 'En présentiel ou à distance',
  onsite: 'En présentiel',
  remote: 'À distance',
};

type ShareButtonProps = {
  event?: StrapiEvent;
};

const ShareButton = ({ event }: ShareButtonProps) => {
  const desktop = useDesktop();

  const shareAction = useCallback(() => {
    const publication = event!.publication;
    const start = dayjs(publication.start);

    const subject = `Événement ${ENV.CLIENT_NAME} : "${event!.name}"`;

    const body = [
      'Bonjour,',
      '',
      `J'ai remarqué cet événement du ${ENV.CLIENT_NAME} qui pourrait vous intéresser :`,
      '',
      `• ${event!.name}`,
      `• Le ${start.format('DD/MM/YYYY')} à ${start.format('HH[h]mm')}`,
      `• Lien : ${window.location.href}`,
      `• ${LABELS_BY_MODE[publication.mode ?? 'onsite']}`,
      event!.webinar ? `• Webinaire : ${prependHttp(event!.webinar)}` : null,
      event!.address ? `• Lieu : ${event!.address}` : null,
    ]
      .filter((x) => x !== null)
      .join('\n');

    const url = window.location.href;

    const hasNativeShare = Boolean(window.navigator.share);
    if (desktop || !hasNativeShare) {
      window.open(
        `mailto:?body=${encodeURIComponent(body)}&subject=${encodeURIComponent(subject)}`,
        '_self',
      );
    } else {
      window.navigator.share({
        url,
        title: subject,
        text: body,
      });
    }
  }, [event, desktop]);

  return (
    <>
      <Button className="share" onClick={shareAction} disabled={!event}>
        {ShareIcon}Partager
      </Button>
    </>
  );
};

export default ShareButton;
