import { ReactNode } from 'react';

export const joinComponents = (nodes: ReactNode[], joiner: ReactNode) => {
  return nodes.reduce(
    (acc: ReactNode[], curr) => [...acc, ...(acc.length > 0 ? [joiner] : []), curr],
    [],
  );
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

/** Add http(s) on a link if it is missing. Taken from https://www.npmjs.com/package/prepend-http */
export const prependHttp = (url: string, { https = true } = {}) => {
  url = url.trim();
  if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
    return url;
  }
  return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
};
