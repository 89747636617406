import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TourProvider, useTour } from '@reactour/tour';
import { StepType } from '@reactour/tour/dist/types';
import { useMatch } from 'react-router-dom';
import { Onboarding } from '../api/strapi';
import { useUser } from './UserProvider';
import { useDesktop } from '../hooks';
import { ENV } from '../utils/environment';

const menuMarketsStep = {
  selector: '.contentMenu .marketsMenu',
  content: (
    <>
      <strong>Mes marchés</strong>
      <p>Accédez aux marchés dont bénéficie votre organisation.</p>
    </>
  ),
};
const menuArborescenceStep = {
  selector: '.contentMenu .arborescenceMenu',
  content: (
    <>
      <strong>Mes adhésions</strong>
      <p>
        Cliquez sur “Mes adhésions” pour consulter les offres du {ENV.CLIENT_NAME} auxquelles votre
        organisation est adhérente. Ces offres sont organisées par thématique métier que ce soient
        des marchés, des compétences ou des délégations de services publics. En cliquant sur « voir
        toutes mes adhésions » vous visualisez l’ensemble des offres dont votre organisation
        bénéficie.
      </p>
    </>
  ),
};
const menuMetricsStep = {
  selector: '.contentMenu .indicatorsMenu',
  content: (
    <>
      <strong>Mes indicateurs</strong>
      <p>
        Consultez les indicateurs de votre organisation pour suivre vos activités avec le{' '}
        {ENV.CLIENT_NAME}
        en fonction de vos adhésions.
      </p>
    </>
  ),
};
const menuEventsStep = {
  selector: '.contentMenu .eventsMenu',
  content: (
    <>
      <strong>Mes évènements</strong>
      <p>
        Consultez les évènements auxquels votre organisation, ou vous, êtes invités, et
        inscrivez-vous directement en ligne.
      </p>
    </>
  ),
};

const onboardingSteps: Record<Onboarding, StepType[]> = {
  home: [
    {
      selector: 'header .logo',
      content: (
        <>
          <strong>Page d'accueil</strong>
          <p>
            Découvrez la synthèse des informations de votre organisation : vos indicateurs
            principaux s’ils existent, vos nouveaux marchés, les prochains évènements et les
            actualités en lien avec vos adhésions au {ENV.CLIENT_NAME}.
          </p>
        </>
      ),
    },
    {
      selector: '.content .markets',
      content: (
        <>
          <strong>Mes marchés</strong>
          <p>
            Accéder directement aux marchés dont bénéficie votre organisation en cliquant sur ce
            lien.
          </p>
        </>
      ),
    },
    {
      selector: 'header .menu',
      content: (
        <>
          <strong>Menu</strong>
          <p>Cliquez sur ce bouton pour ouvrir le menu latéral.</p>
          <p>
            Vous y trouverez tous les contenus proposés par l’espace adhérents pour piloter votre
            relation avec le {ENV.CLIENT_NAME} : vos marchés, vos adhésions, vos indicateurs, vos
            évènements et vos instances. Ce contenu est personnalisé en fonction des adhésions de
            votre organisation au {ENV.CLIENT_NAME} et ordonné par thématique métier.
          </p>
        </>
      ),
    },
  ],
  menu: [],
  metrics: [
    {
      selector: '.content .update',
      content: (
        <>
          <strong>Date de mise à jour</strong>
          <p>Cette date correspond à la date de validité de l’ensemble des indicateurs.</p>
        </>
      ),
    },
    {
      selector: '.content .tabs',
      content: (
        <>
          <strong>Onglets</strong>
          <p>
            Les indicateurs sont organisés par onglets thématiques et dépendent des adhésions de
            votre organisation au {ENV.CLIENT_NAME}. Ils vous permettent d’obtenir des données
            chiffrées sur certaines de vos activités avec le {ENV.CLIENT_NAME}.
          </p>
        </>
      ),
    },
    {
      selector: '#bottom .more',
      content: (
        <>
          <strong>En savoir plus</strong>
          <p>
            Ce bouton vous permet d’accéder à l’offre du {ENV.CLIENT_NAME} qui correspond à la
            famille d’indicateurs affichés.
          </p>
        </>
      ),
    },
  ],
  events: [
    {
      selector: '.content .tabs',
      content: (
        <>
          <strong>Onglets</strong>
          <p>Choisissez la période d’évènements que vous souhaitez afficher.</p>
        </>
      ),
    },
    {
      selector: '.filter',
      content: (
        <>
          <strong>Filtrer</strong>
          <p>Pour obtenir des critères de filtres plus avancés, utilisez le filtre.</p>
        </>
      ),
    },
  ],
  event: [
    {
      selector: '.content .actions .attendance',
      content: (
        <>
          <strong>M’inscrire</strong>
          <p>Consultez votre statut d’inscription à l’évènement et modifiez le à tout moment.</p>
        </>
      ),
    },
    {
      selector: '.content .actions .share',
      content: (
        <>
          <strong>Partager</strong>
          <p>Cet évènement peut intéresser l’un de vos collègues ? N’hésitez pas à le partager.</p>
        </>
      ),
    },
  ],
};

type OnboardingInfo = {
  hasOnboarding: boolean;
  openPageOnboarding: () => void;
  openPageOnboardingIfNeeded: () => void;
  openMenuOnboardingIfNeeded: (
    hasMarkets: boolean,
    hasArborescence: boolean,
    hasMetrics: boolean,
  ) => void;
};

const OnboardingContext = createContext<OnboardingInfo | undefined>(undefined);

const OnboardingProvider = ({ children }: PropsWithChildren<{}>) => {
  const { user, fetch } = useUser();

  // List of seen onboardings (undefined while not yet loaded)
  const [seenOnboardings, setSeenOnboardings] = useState<Onboarding[]>();

  useEffect(() => {
    setSeenOnboardings(user?.onboardings.map((onboarding) => onboarding.value));
  }, [user]);

  const handleSeenOnboarding = async (onboarding: Onboarding) => {
    setSeenOnboardings([...(seenOnboardings ?? []), onboarding]);
    await fetch(`/api/users/onboarding/${onboarding}`, { method: 'POST' });
  };

  const isHome = useMatch('/');
  const isIndicators = useMatch('/indicateurs');
  const isEvents = useMatch('/evenements');
  const isEvent = useMatch('/evenement/:id');
  let currentOnboarding: Onboarding | null = null;
  if (isHome) {
    currentOnboarding = 'home';
  } else if (isIndicators) {
    currentOnboarding = 'metrics';
  } else if (isEvents) {
    currentOnboarding = 'events';
  } else if (isEvent) {
    currentOnboarding = 'event';
  }
  const pageSteps = currentOnboarding ? onboardingSteps[currentOnboarding] : [];
  return (
    <TourProvider
      steps={pageSteps}
      showCloseButton={false}
      showBadge={false}
      disableInteraction={true}
      disableFocusLock={true}
      key={currentOnboarding}
    >
      <InnerOnboardingProvider
        seenOnboardings={seenOnboardings}
        onSeenOnboarding={handleSeenOnboarding}
        currentOnboarding={currentOnboarding}
        pageSteps={pageSteps}
      >
        {children}
      </InnerOnboardingProvider>
    </TourProvider>
  );
};

type InnerOnboardingProviderProps = PropsWithChildren<{
  seenOnboardings: Onboarding[] | undefined;
  onSeenOnboarding: (onboarding: Onboarding) => void;
  currentOnboarding: Onboarding | null;
  pageSteps: StepType[];
}>;

const InnerOnboardingProvider = ({
  seenOnboardings,
  onSeenOnboarding,
  currentOnboarding,
  pageSteps,
  children,
}: InnerOnboardingProviderProps) => {
  const { isOpen, setIsOpen, setCurrentStep, setSteps } = useTour();
  const desktop = useDesktop();
  const { isAuthenticated } = useUser();

  const openPageOnboarding = () => {
    setSteps(pageSteps);
    setCurrentStep(0);
    setIsOpen(true);
  };

  const openOnboardingIfNeeded = useCallback(
    async (onboarding: Onboarding, steps: StepType[]) => {
      if (
        isAuthenticated &&
        desktop &&
        !isOpen &&
        seenOnboardings &&
        !seenOnboardings.includes(onboarding)
      ) {
        setSteps(steps);
        setCurrentStep(0);
        onSeenOnboarding(onboarding);
        // Small delay to allow for animations and data load
        setTimeout(() => setIsOpen(true), 300);
      }
    },
    [
      isAuthenticated,
      desktop,
      isOpen,
      seenOnboardings,
      setSteps,
      setCurrentStep,
      onSeenOnboarding,
      setIsOpen,
    ],
  );

  const openPageOnboardingIfNeeded = useCallback(() => {
    if (currentOnboarding) {
      openOnboardingIfNeeded(currentOnboarding, pageSteps).then();
    }
  }, [currentOnboarding, openOnboardingIfNeeded, pageSteps]);

  const openMenuOnboardingIfNeeded = useCallback(
    (hasMarkets: boolean, hasArborescence: boolean, hasMetrics: boolean) => {
      const menuSteps: StepType[] = [];
      if (hasMarkets) {
        menuSteps.push(menuMarketsStep);
      }
      if (hasArborescence) {
        menuSteps.push(menuArborescenceStep);
      }
      if (hasMetrics) {
        menuSteps.push(menuMetricsStep);
      }
      menuSteps.push(menuEventsStep);
      return openOnboardingIfNeeded('menu', menuSteps);
    },
    [openOnboardingIfNeeded],
  );

  return (
    <OnboardingContext.Provider
      value={{
        hasOnboarding: Boolean(currentOnboarding),
        openPageOnboarding,
        openPageOnboardingIfNeeded,
        openMenuOnboardingIfNeeded,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingInfo => {
  const onboardingInfo = useContext(OnboardingContext);
  if (onboardingInfo === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return onboardingInfo;
};

export default OnboardingProvider;
