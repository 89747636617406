import { StrapiTag } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import {
  DocumentsSection,
  PagesSection,
  PreviewSection,
  RichTextSection,
  UpdateSection,
} from '../../components/sections';
import { useParams } from 'react-router-dom';
import { PreviewPageProps } from '../page-types';
import Error from '../../components/Error';
import { useDesktop, usePageFetch } from '../../hooks';
import BackButton from '../../components/BackButton';
import Background from '../../components/Background';

const TagPage = ({ preview }: PreviewPageProps) => {
  const { id } = useParams();
  const { data, error } = usePageFetch<StrapiTag>(
    `/api/tags/${id}${preview ? '?preview=true' : ''}`,
  );
  const desktop = useDesktop();

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      {preview && <PreviewSection />}
      <Header />
      {data && (
        <div id="tagPage">
          <div id="top">
            <BackButton />
            <div className="content">
              <h1>
                {data.title}
                {preview && <span className="preview"> (prévisualisation)</span>}
              </h1>
              <UpdateSection entity={data} />
            </div>
          </div>
          <div id="bottom">
            <main>
              {data.image && (
                <div className="cover">
                  <Background className="image" file={data.image} />
                </div>
              )}
              {!desktop && <PagesSection pages={data.pages} />}
              <RichTextSection html={data.content} />
              <DocumentsSection documents={data.documents} title="Les documents liés" />
            </main>
            {desktop && (
              <aside>
                <PagesSection pages={data.pages} />
              </aside>
            )}
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default TagPage;
