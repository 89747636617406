import dayjs from 'dayjs';

import InstancePage, { Instance } from './InstancePage';
import { StrapiInstanceBureau } from '../../api/strapi';

const transformResponse = (data: StrapiInstanceBureau): Instance => ({
  title: data.title,
  menu: data.menu,
  content: data.content,
  referrals: data.referrals,
  items: data.bureaux.map((itemData) => ({
    id: itemData.id,
    date: dayjs(itemData.date),
    event: itemData.event,
    attachments: [
      {
        type: 'Ordre du jour',
        file: itemData.agenda,
      },
      {
        type: 'Dossier du bureau',
        file: itemData.bureauFile,
      },
      {
        type: 'Compte-rendu',
        file: itemData.report,
      },
    ],
  })),
});

const InstanceBureauxPage = () => (
  <InstancePage<StrapiInstanceBureau>
    itemTitle="Bureau"
    query="/api/instance-bureau"
    transformResponse={transformResponse}
  />
);

export default InstanceBureauxPage;
