import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import clsx from 'clsx';
import { CloseIcon } from '../../components/svg';
import { validateEmail } from '../../utils/validation';
import { useApiQuery } from '../../hooks';
import EmailTextField from '../../components/EmailTextField';

type ForgottenPasswordProps = {
  defaultEmail: string;
};

const ForgottenPassword = ({ defaultEmail }: ForgottenPasswordProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(defaultEmail);
  const [error, setError] = useState<string>();
  const [sent, setSent] = useState(false);
  const { doApiQuery } = useApiQuery();

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSent(false);
      setError(undefined);
    }, 100);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const handleSubmit = () =>
    doApiQuery('la réinitialisation de mot de passe', async () => {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        body: new URLSearchParams({ email: email.toLowerCase() }),
      });
      if (response.ok) {
        setSent(true);
        setError(undefined);
      } else if (response.status === 400) {
        setError("Nous n'avons trouvé aucun compte avec cette adresse.");
      } else {
        setError('Impossible de réinitialiser le mot de passe. Veuillez réessayer plus tard.');
      }
      return response;
    });

  const emailValid = validateEmail(email);

  return (
    <>
      <div className="forgotten-password">
        <ButtonBase onClick={handleClickOpen}>Mot de passe oublié ?</ButtonBase>
      </div>
      <Dialog className="forgotten-password-dialog" open={open} onClose={handleClose}>
        {sent ? (
          <div className="infoSuccess">
            <Button className="close" onClick={handleClose}>
              {CloseIcon}
            </Button>
            <DialogTitle className="sent">C'est envoyé !</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Nous vous avons envoyé un e-mail afin de ré-initialiser votre mot de passe.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Retour a la page connexion
              </Button>
            </DialogActions>
          </div>
        ) : (
          <>
            <DialogTitle className={clsx(sent && 'sent')}>Mot de passe oublié</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Saisissez ci-dessous l’adresse e-mail que vous utilisez pour accéder à l’espace
                adhérent. Vous y recevrez un e-mail vous permettant de ré-initialiser votre mot de
                passe.
              </DialogContentText>
              <EmailTextField
                id="email"
                label="Adresse e-mail"
                required={true}
                value={email}
                onChange={handleEmailChange}
                placeholder="Saisissez votre adresse e-mail"
              />
              {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Annuler
              </Button>
              <Button type="submit" onClick={handleSubmit} color="primary" disabled={!emailValid}>
                Valider
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ForgottenPassword;
