import PasswordChange, { PasswordChangeStep } from '../../components/PasswordChange';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useUser } from '../../providers/UserProvider';
import { useApiQuery } from '../../hooks';

type ResetPasswordResponse = {
  jwt: string;
  user: any;
};

const NewPasswordPage = () => {
  const { saveJwt } = useUser();
  const { token } = useParams();
  const { doApiQuery } = useApiQuery();

  const [step, setStep] = useState<PasswordChangeStep>(token ? 'password' : 'error');

  const saveNewPassword = useCallback(
    async (password: string) => {
      const success = await doApiQuery('le changement de mot de passe', async () => {
        const response = await fetch('/api/auth/reset-password', {
          method: 'POST',
          body: new URLSearchParams({ code: token!, password, passwordConfirmation: password }),
        });

        if (response.ok) {
          const body: ResetPasswordResponse = await response.json();
          saveJwt(body.jwt);
          setStep('validated');
        }
        return response;
      });
      if (!success) {
        setStep('error');
      }
    },
    [doApiQuery, saveJwt, token],
  );

  return (
    <PasswordChange
      step={step}
      title="Nouveau mot de passe"
      onConfirm={(password) => saveNewPassword(password)}
      passwordContent="Veuillez saisir votre nouveau mot de passe."
      validatedContent={<p>Votre nouveau mot de passe a bien été enregistré.</p>}
    />
  );
};

export default NewPasswordPage;
