import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import RankAutocomplete from '../../components/RankAutocomplete';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { validateNotBlank, validatePhone } from '../../utils/validation';
import { ManualAccessRequestFormData } from './ManualAccessRequestPage';
import EmailTextField from '../../components/EmailTextField';
import PhoneTextField from '../../components/PhoneTextField';
import { ENV } from '../../utils/environment';

type AccessRequestFormProps = {
  initialEmail: string;
  onConfirm: (value: ManualAccessRequestFormData) => void;
};

const ManualAccessRequestForm = ({ initialEmail, onConfirm }: AccessRequestFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showErrors, setShowErrors] = useState(false);

  const [value, setValue] = useState<ManualAccessRequestFormData>({
    email: initialEmail,
    firstname: '',
    lastname: '',
    account: '',
    title: '',
    phone: '',
    rank: null,
  });

  const validRank = Boolean(value.rank);
  const validTitle = validateNotBlank(value.title);
  const validPhone = validatePhone(value.phone);
  const validFirstname = validateNotBlank(value.firstname);
  const validLastname = validateNotBlank(value.lastname);
  const validAccount = validateNotBlank(value.account);

  const valid =
    validRank && validTitle && validPhone && validFirstname && validLastname && validAccount;

  const handleSave = () => {
    if (!valid) {
      enqueueSnackbar('Certains champs renseignés ne sont pas valides.', { variant: 'error' });
      setShowErrors(true);
    } else {
      onConfirm(value);
    }
  };

  return (
    <div className="formStep">
      <h1>
        <strong>Demande d'accès à l'espace adhérents</strong>
      </h1>
      <div className="explanation">
        <p>
          Afin de pouvoir traiter votre demande d'accès à l'espace adhérents du {ENV.CLIENT_NAME},
          merci de compléter les informations suivantes.
        </p>
      </div>
      <EmailTextField value={value.email} label="Email" disabled={true} />
      <TextField
        value={value.lastname}
        label="Nom"
        className="mandatory"
        error={showErrors && !validLastname}
        helperText={showErrors && !validLastname && 'Ce champ est obligatoire.'}
        onChange={(event) =>
          setValue({
            ...value,
            lastname: event.currentTarget.value,
          })
        }
      />
      <TextField
        value={value.firstname}
        label="Prénom"
        className="mandatory"
        error={showErrors && !validFirstname}
        helperText={showErrors && !validFirstname && 'Ce champ est obligatoire.'}
        onChange={(event) =>
          setValue({
            ...value,
            firstname: event.currentTarget.value,
          })
        }
      />
      <TextField
        value={value.account}
        label="Organisation"
        className="mandatory"
        error={showErrors && !validAccount}
        helperText={showErrors && !validAccount && 'Ce champ est obligatoire.'}
        onChange={(event) =>
          setValue({
            ...value,
            account: event.currentTarget.value,
          })
        }
      />
      <RankAutocomplete
        value={value.rank ?? null}
        label="Fonction au sein de l'organisation"
        className="mandatory"
        onChange={(rank) =>
          setValue({
            ...value,
            rank: rank,
          })
        }
        inputProps={{
          className: clsx({ error: showErrors && !validRank }),
        }}
      />
      <TextField
        value={value.title}
        label="Titre au sein de l'organisation"
        className="mandatory"
        error={showErrors && !validTitle}
        helperText={showErrors && !validTitle && 'Ce champ est obligatoire.'}
        onChange={(event) =>
          setValue({
            ...value,
            title: event.currentTarget.value,
          })
        }
      />
      <PhoneTextField
        value={value.phone}
        label="Téléphone"
        className="mandatory"
        error={showErrors && !validPhone}
        onChange={(event) =>
          setValue({
            ...value,
            phone: event.currentTarget.value,
          })
        }
      />
      <div className="actions">
        <Button color="primary" variant="contained" onClick={handleSave}>
          Demander l'accès
        </Button>
      </div>
    </div>
  );
};

export default ManualAccessRequestForm;
