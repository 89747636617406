import dayjs from 'dayjs';

import InstancePage, { Instance } from './InstancePage';
import { StrapiInstanceCommittee } from '../../api/strapi';

const transformResponse = (data: StrapiInstanceCommittee): Instance => ({
  title: data.title,
  menu: data.menu,
  content: data.content,
  referrals: data.referrals,
  items: data.committees.map((itemData) => ({
    id: itemData.id,
    date: dayjs(itemData.date),
    event: itemData.event,
    attachments: [
      {
        type: 'Ordre du jour',
        file: itemData.agenda,
      },
      {
        type: 'Dossier des affaires',
        file: itemData.caseFile,
      },
      {
        type: 'Compte-rendu',
        file: itemData.report,
      },
      {
        type: 'Procès verbal',
        file: itemData.transcript,
      },
      {
        type: 'Délibérations',
        file: itemData.deliberations,
      },
    ],
  })),
});

const InstanceCommitteesPage = () => (
  <InstancePage<StrapiInstanceCommittee>
    itemTitle="Comité"
    query="/api/instance-committee"
    transformResponse={transformResponse}
  />
);

export default InstanceCommitteesPage;
