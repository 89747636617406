import React from 'react';
import { Link } from 'react-router-dom';
import { Copyright } from './Copyright';
import { Tutorial } from './Tutorial';

export function Footer() {
  return (
    <footer>
      <div className="content">
        <div className="text">
          <Copyright />
          <Tutorial />
        </div>
        <ul className="links">
          <li>
            <Link to="/a-propos">À propos</Link>
          </li>
          <li>
            <Link to="/contact">Nous contacter</Link>
          </li>
          <li>
            <Link to="/cgu">CGU</Link>
          </li>
          <li>
            <Link to="/rgpd">RGPD</Link>
          </li>
          <li>
            <Link to="/mentions-legales">Mentions légales</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
