import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { SearchResultCartouche } from '../../components/searchResults';
import SearchPageFiltersHeader from './SearchPageFiltersHeader';
import { SearchResults } from '../../api/strapi';
import React, { useMemo } from 'react';
import qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import { Link, useSearchParams } from 'react-router-dom';
import { useMobileOrTablet, usePageFetch } from '../../hooks';
import Error from '../../components/Error';
import { CircularProgress } from '@mui/material';
import { Search } from '../../components/header/Search';
import { ErrorBoundary } from '@sentry/react';
import BackButton from '../../components/BackButton';

/** Corresponds to the ES type field. Except all which is no filter. */
export type SearchTypeFilter = 'all' | 'adhesion' | 'event' | 'file' | 'instance' | 'metric';

export type SearchFilters = {
  query: string;
  type: SearchTypeFilter;
  start?: Dayjs;
  end?: Dayjs;
  tags: number[];
};

const filtersToQuery = (newFilters: SearchFilters) =>
  qs.stringify(
    {
      ...newFilters,
      start: newFilters.start?.toISOString(),
      end: newFilters.end?.toISOString(),
      type: newFilters.type,
    },
    { encodeValuesOnly: true },
  );

const useSearchFilter = (): [SearchFilters, (filter: SearchFilters) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Use qs to parse arrays, since it handles indexes differently from the standard URLSearchParams
  const q = qs.parse(searchParams.toString());

  const filters: SearchFilters = {
    query: searchParams.get('query') ?? '',
    start: searchParams.has('start') ? dayjs(searchParams.get('start')) : undefined,
    end: searchParams.has('end') ? dayjs(searchParams.get('end')) : undefined,
    type: (searchParams.get('type') as SearchTypeFilter) ?? 'all',
    tags: ((q.tags as string[]) ?? []).map((tagId) => parseInt(tagId)),
  };

  const setFilters = (newFilters: SearchFilters) => setSearchParams(filtersToQuery(newFilters));

  return [filters, setFilters];
};

const SearchPage = () => {
  const mobileOrTablet = useMobileOrTablet();
  const [filters, setFilters] = useSearchFilter();
  const skip = !filters.query;

  const fetchQuery = useMemo(() => filtersToQuery(filters), [filters]);
  const { data, error } = usePageFetch<SearchResults>(`api/search?${fetchQuery}`, skip);

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      <div id="searchPage">
        <div id="top">
          <BackButton />
          <div className="content">
            {mobileOrTablet ? <Search /> : <h1>{filters.query ? filters.query : 'Recherche'}</h1>}
            {skip ? (
              <div className="mobilePrompt">Veuillez saisir votre recherche</div>
            ) : (
              <>
                <div className="count">
                  {data ? `${data.total} résultats de recherche` : 'Recherche en cours...'}
                </div>
                <SearchPageFiltersHeader
                  filters={filters}
                  tags={data?.tags}
                  onChange={setFilters}
                />
              </>
            )}
          </div>
        </div>
        {!skip && (
          <div id="bottom">
            {data ? (
              <ul className="list">
                {data.results.length > 0 ? (
                  data.results.map((result) => (
                    <ErrorBoundary key={result.searchId}>
                      <li className="item">
                        <SearchResultCartouche result={result} filters={filters} />
                      </li>
                    </ErrorBoundary>
                  ))
                ) : (
                  <div className="no-results">
                    <h2>Nous sommes désolé mais nous n'avons rien trouvé</h2>
                    <p>Essayez de reformuler votre recherche ou revenez sur la page d'accueil</p>
                    <Link to="/">Retour à l'accueil</Link>
                  </div>
                )}
              </ul>
            ) : (
              <div className="loader">
                <CircularProgress />
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SearchPage;
