import { InputAdornment, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { PasswordIcon } from './svg';
import { useState } from 'react';

type PasswordFieldProps = Omit<TextFieldProps, 'type'>;

const PasswordField = ({
  InputLabelProps,
  InputProps,
  className,
  ...props
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <MuiTextField
      className={`passwordField ${className ?? ''}`}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>
            {PasswordIcon}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      {...props}
    />
  );
};

export default PasswordField;
