import { createInstance, useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ENV } from '../utils/environment';

export const matomoInstance = () => {
  const matomoId = ENV.MATOMO_ID;
  return createInstance({
    urlBase: 'https://sipperec.fr.matomo02.stratis.pro',
    disabled: matomoId == null,
    siteId: matomoId ?? 1, // needs a value otherwise it fails
    configurations: {
      disableCookies: true,
    },
  });
};

export const MatomoTracker = () => {
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({});
  }, [location, trackPageView]);

  return <></>;
};
