import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { useMemo } from 'react';

export type FromLocationState = { from: Location };

/**
 * Checks that the user is not authenticated before giving access to its children routes.
 * If the user is authenticated, redirects to the user's home page instead.
 */
const RequireNotAuth = () => {
  const { isAuthenticated } = useUser();
  // We take isAuthenticated state into account only at the component loading otherwise it interferes with the login process
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authenticatedOnPageLoad = useMemo(() => isAuthenticated, []);
  return authenticatedOnPageLoad ? <Navigate to="/" replace /> : <Outlet />;
};

export default RequireNotAuth;
