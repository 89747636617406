import React, { useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import {
  StrapiAccess,
  StrapiAccessStatus,
  StrapiAccessSummary,
  StrapiAccessUpdateCommand,
} from '../../api/strapi';
import classNames from 'classnames';
import AccountAccessEdit from './AccountAccessEdit';
import { ENV } from '../../utils/environment';

type AccessesComponentProps = {
  accesses: StrapiAccess[];
  filter: string;
  onDelete: (id: number) => void;
  onDisable: (id: number) => void;
  onEnable: (id: number) => void;
  onResendInvitation: (id: number) => void;
  onUpdate: (command: StrapiAccessUpdateCommand) => Promise<boolean>;
};

type AccessRow = {
  id: number;
  admin: boolean;
  name: string;
  email: string;
  title: string;
  status: StrapiAccessStatus;
  access: StrapiAccessSummary;
};

const STATUS_LABEL_MAPPER: Record<StrapiAccessStatus, string> = {
  active: 'Actif',
  invited: 'Invité',
  disabled: 'Désactivé',
};

const AccountAccessTable = ({
  accesses,
  filter,
  onDelete,
  onEnable,
  onDisable,
  onResendInvitation,
  onUpdate,
}: AccessesComponentProps) => {
  const [pageSize, setPageSize] = useState(20);
  const lcFilter = filter.toLowerCase();
  const [toDelete, setToDelete] = useState<AccessRow | null>(null);

  const columns: GridColDef[] = [
    {
      field: 'admin',
      headerName: '',
      description: 'Statut administrateur de cet utilisateur pour votre organisation.',
      flex: 0,
      width: 48,
      renderCell: (params) => {
        return params.value ? (
          <div className="admin">
            <Tooltip
              placement="top"
              arrow
              title="Administrateur espace adhérents de l'organisation"
            >
              <i />
            </Tooltip>
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Nom',
      description: 'Nom de cet utilisateur.',
      flex: 1,
    },
    { field: 'email', headerName: 'E-mail', flex: 1 },
    {
      field: 'title',
      headerName: 'Titre',
      description: 'Titre de cet utilisateur au sein de votre organisation',
      flex: 1,
      renderCell: (params) => {
        if (params.value && params.value.length > 30) {
          return (
            <Tooltip title={params.value}>
              <div className="title">{params.value}</div>
            </Tooltip>
          );
        }
        return params.value;
      },
    },
    {
      field: 'status',
      headerName: 'Accès',
      description: 'Statut de cet utilisateur au sein de votre organisation',
      width: 105,
      renderCell: (params) => {
        const status = params.value as StrapiAccessStatus;

        return (
          <Tooltip title={STATUS_LABEL_MAPPER[status]}>
            <div className={classNames('status', status)} />
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 144,
      renderCell: (params: GridRenderCellParams<AccessRow>) => {
        const status = params.row.status;
        const id = params.id as number;

        return (
          <div className="actions">
            <AccountAccessEdit access={params.row.access} onUpdate={onUpdate} />
            {status === 'invited' && (
              <Tooltip placement="top" arrow title="Renvoyer l'invitation">
                <Button className="resendInvitation" onClick={() => onResendInvitation(id)} />
              </Tooltip>
            )}
            {status === 'disabled' && (
              <Tooltip placement="top" arrow title="Rendre les droits d'accès">
                <Button className="enable" onClick={() => onEnable(id)} />
              </Tooltip>
            )}
            {status === 'active' && (
              <Tooltip placement="top" arrow title="Retirer les droits d'accès">
                <Button className="disable" onClick={() => onDisable(id)} />
              </Tooltip>
            )}
            <Tooltip placement="top" arrow title="Cette personne a quitté mon organisation">
              <Button className="delete" onClick={() => setToDelete(params.row)} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows: AccessRow[] = accesses
    .map((access) => ({
      id: access.id,
      admin: access.admin,
      name: [
        // Nom en premier pour le tri
        access.user.contact.lastname,
        access.user.contact.firstname,
      ]
        .filter((str) => str?.length > 0)
        .join(' '),
      email: access.user.email,
      title: access.job.title,
      status: access.status,
      access: {
        id: access.id,
        email: access.user.email,
        civility: access.user.contact.civility,
        firstName: access.user.contact.firstname,
        lastName: access.user.contact.lastname,
        jobTitle: access.job.title,
        jobRank: access.job.rank,
        admin: access.admin,
      },
    }))
    .filter(
      (row) =>
        lcFilter.length === 0 ||
        row.name?.toLowerCase().includes(lcFilter) ||
        row.title?.toLowerCase().includes(lcFilter) ||
        row.email?.toLowerCase().includes(lcFilter),
    );

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[20, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
      <Dialog open={Boolean(toDelete)} onClose={() => setToDelete(null)}>
        <>
          <DialogTitle>Supprimer les accès</DialogTitle>
          <DialogContent>
            <DialogContentText>
              La suppression des accès de <strong>{toDelete?.name}</strong> à votre organisation sur
              l'espace adhérents du {ENV.CLIENT_NAME} est définitive. Ne validez que si
              l'utilisateur a quitté votre organisation.{' '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setToDelete(null)}>
              Annuler
            </Button>
            <Button
              onClick={() => {
                onDelete(toDelete!.id);
                setToDelete(null);
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default AccountAccessTable;
