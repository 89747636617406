import React, { useState } from 'react';
import { Button, Checkbox, Drawer, FormControlLabel } from '@mui/material';
import TextField from '../../components/TextField';
import { DesktopDatePicker } from '@mui/lab';
import {
  BackIcon,
  MenuArborescenceIcon,
  MenuDocumentsIcon,
  MenuEventsIcon,
  MenuInstancesIcon,
  MenuMetricsIcon,
} from '../../components/svg';
import { StrapiTag } from '../../api/strapi';
import { SearchFilters, SearchTypeFilter } from './SearchPage';

type SearchFiltersHeaderProps = {
  filters: SearchFilters;
  // These tags should depend on the query without the tag filter
  tags?: StrapiTag[];
  onChange: (filters: SearchFilters) => void;
};

const SearchPageFiltersHeader = ({ filters, tags, onChange }: SearchFiltersHeaderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [editedFilters, setEditedFilters] = useState<SearchFilters>(filters);

  const sortedTags = [...(tags ?? [])];
  sortedTags.sort((a, b) => a.title.localeCompare(b.title));

  const hasFilters = Boolean(filters.start) || Boolean(filters.end) || filters.tags.length > 0;

  const openMenu = () => {
    setEditedFilters(filters);
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const toggleTag = (tagId: number) => {
    let newTags = new Set(editedFilters.tags);
    if (newTags.has(tagId)) {
      newTags.delete(tagId);
    } else {
      newTags.add(tagId);
    }
    setEditedFilters({ ...editedFilters, tags: Array.from(newTags) });
  };

  return (
    <>
      <div className="options">
        <ul className="tabs">
          <TypeTab filters={filters} type="all" label="Tous" onChange={onChange} />
          <TypeTab
            filters={filters}
            type="adhesion"
            label="Adhésions"
            icon={MenuArborescenceIcon}
            onChange={onChange}
          />
          <TypeTab
            filters={filters}
            type="event"
            label="Évènements"
            icon={MenuEventsIcon}
            onChange={onChange}
          />
          <TypeTab
            filters={filters}
            type={'file'}
            label="Documents"
            icon={MenuDocumentsIcon}
            onChange={onChange}
          />
          <TypeTab
            filters={filters}
            type={'instance'}
            label="Instances"
            icon={MenuInstancesIcon}
            onChange={onChange}
          />
          <TypeTab
            filters={filters}
            type={'metric'}
            label="Indicateurs"
            icon={MenuMetricsIcon}
            onChange={onChange}
          />
        </ul>
        <ul className="actions">
          <li>
            <Button className="filter" onClick={openMenu}>
              Filtrer
            </Button>
          </li>
          {hasFilters && (
            <li>
              <Button
                className="reset"
                onClick={() => {
                  onChange({ tags: [], type: filters.type, query: filters.query });
                }}
              >
                Réinitialiser<span> les filtres</span>
              </Button>
            </li>
          )}
        </ul>
      </div>
      <Drawer id="filtersMenu" anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="hider">
          <div className="scroll">
            <div className="content">
              <Button className="close" onClick={closeMenu}>
                {BackIcon}Retour
              </Button>
              <h6>Filtrer</h6>
              {(sortedTags?.length ?? 0) > 1 && (
                <div className="filterFacets">
                  <div className="filterTitle">Par tag</div>
                  <ul>
                    {sortedTags?.map((tag) => (
                      <li key={tag.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => toggleTag(tag.id)}
                              checked={editedFilters.tags.includes(tag.id)}
                            />
                          }
                          label={tag.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="filterDates">
                <div className="filterTitle">Par date</div>
                <DesktopDatePicker
                  label="Depuis le"
                  inputFormat="DD/MM/YYYY"
                  value={editedFilters.start ?? null}
                  onChange={(value) =>
                    setEditedFilters({ ...editedFilters, start: value ?? undefined })
                  }
                  renderInput={(params) => {
                    params.inputProps!.placeholder = 'JJ/MM/AAAA';
                    return <TextField {...params} />;
                  }}
                  PaperProps={{ className: 'datePicker' }}
                />
                <DesktopDatePicker
                  label="Jusqu'au"
                  inputFormat="DD/MM/YYYY"
                  value={editedFilters.end ?? null}
                  onChange={(value) =>
                    setEditedFilters({ ...editedFilters, end: value ?? undefined })
                  }
                  renderInput={(params) => {
                    params.inputProps!.placeholder = 'JJ/MM/AAAA';
                    return <TextField {...params} />;
                  }}
                  PaperProps={{ className: 'datePicker' }}
                />
              </div>
              <div className="actions">
                <Button
                  onClick={() => {
                    onChange(editedFilters);
                    setOpen(false);
                  }}
                >
                  Voir les résultats
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SearchPageFiltersHeader;

const TypeTab = ({
  filters,
  type,
  label,
  icon,
  onChange,
}: {
  filters: SearchFilters;
  type: SearchTypeFilter;
  label: string;
  icon?: JSX.Element;
  onChange: (filters: SearchFilters) => void;
}) => {
  return (
    <li className={type}>
      <Button
        className={filters.type === type ? 'active' : ''}
        onClick={() => onChange({ ...filters, type })}
      >
        {icon}
        {label}
      </Button>
    </li>
  );
};
