import qs from 'qs';
import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { usePageFetch } from '../../hooks';
import { GrappPublicationMode, StrapiEvent, StrapiTag } from '../../api/strapi';
import Error from '../../components/Error';
import EventCard from './EventCard';
import EventSearchFiltersHeader from './EventSearchFiltersHeader';
import BackButton from '../../components/BackButton';

export type EventSearchTimeFilter = 'previous' | 'next' | 'all';

export type EventSearchFilters = {
  query: string;
  start?: Dayjs;
  end?: Dayjs;
  time: EventSearchTimeFilter;
  tags: number[];
  modes: GrappPublicationMode[];
};

const filtersToQuery = (newFilters: EventSearchFilters) =>
  qs.stringify(
    {
      ...newFilters,
      start: newFilters.start?.toISOString(),
      end: newFilters.end?.toISOString(),
    },
    { encodeValuesOnly: true },
  );

const useSearchFilter = (): [EventSearchFilters, (filter: EventSearchFilters) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Use qs to parse arrays, since it handles indexes differently from the standard URLSearchParams
  const q = qs.parse(searchParams.toString());

  const filters: EventSearchFilters = {
    query: searchParams.get('query') ?? '',
    start: searchParams.has('start') ? dayjs(searchParams.get('start')).startOf('day') : undefined,
    end: searchParams.has('end') ? dayjs(searchParams.get('end')).startOf('day') : undefined,
    time: (searchParams.get('time') as EventSearchTimeFilter) ?? 'next',
    tags: ((q.tags as string[]) ?? []).map((tagId) => parseInt(tagId)),
    modes: (q.modes as GrappPublicationMode[]) ?? [],
  };

  const setFilters = (newFilters: EventSearchFilters) =>
    setSearchParams(filtersToQuery(newFilters));

  return [filters, setFilters];
};

const EventSearchPage = () => {
  const [filters, setFilters] = useSearchFilter();

  const fetchQuery = useMemo(() => filtersToQuery(filters), [filters]);
  const { data, error } = usePageFetch<StrapiEvent[]>(`api/events?${fetchQuery}`);

  const { data: tagsData, error: tagsError } = usePageFetch<StrapiTag[]>(
    'api/events/availableTags',
  );

  if (error) {
    return <Error message={error} />;
  }
  if (tagsError) {
    return <Error message={tagsError} />;
  }

  return (
    <>
      <Header />
      <div id="eventSearchPage">
        <div id="top">
          <BackButton variant="dark" />
          <div className="content">
            <h1>Mes évènements</h1>
            <EventSearchFiltersHeader filters={filters} tags={tagsData} onChange={setFilters} />
          </div>
        </div>
        <div id="bottom">
          <ul className="list">
            {data?.map((event) => (
              <li className="item" key={event.id}>
                <EventCard event={event} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventSearchPage;
