import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';

export type FromLocationState = { from: Location };

const RequireAuth = () => {
  const { isAuthenticated, user } = useUser();
  const location = useLocation();

  if (!isAuthenticated) {
    // Redirect them to the /connexion page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  }
  if (!user) {
    // The user is authenticated, but does not have their details yet, so defer the render.
    return null;
  } else if (user.accounts.length === 0) {
    // The user has no account left, move him to the "you have no more access page.
    return <Navigate to="/compte-desactive" replace />;
  } else if (user.needsConfirmation && user.confirmationToken !== null) {
    return (
      <Navigate
        to={`/creation-compte/${user.confirmationToken}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default RequireAuth;
