import React from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { LocalizationProvider } from '@mui/lab';
import { ErrorBoundary } from 'react-error-boundary';

import * as Sentry from './sentry/sentry';
import { defaultTheme } from './theme/theme';
import { UserProvider } from './providers/UserProvider';
import OnboardingProvider from './providers/OnboardingProvider';
import routes from './routes';
import Error from './components/Error';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { matomoInstance, MatomoTracker } from './matomo/matomo';
import { initEnvironment } from './utils/environment';

dayjs.locale('fr');

Sentry.init();

initEnvironment();

const App = () => (
  <div id="App" data-testid="App">
    <LocalizationProvider dateAdapter={DateAdapter} locale="fr">
      <ThemeProvider theme={defaultTheme}>
        <MatomoProvider value={matomoInstance()}>
          <BrowserRouter>
            <ErrorBoundary
              fallbackRender={({ resetErrorBoundary }) => (
                <Error message="Erreur inconnue" onHomeClick={resetErrorBoundary} />
              )}
            >
              <UserProvider>
                <OnboardingProvider>
                  <SnackbarProvider>
                    <MatomoTracker />
                    <AppRoutes />
                  </SnackbarProvider>
                </OnboardingProvider>
              </UserProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </MatomoProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </div>
);

const AppRoutes = () => {
  return useRoutes(routes);
};

export default App;
