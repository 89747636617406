import { GrappAttendanceStatus, StrapiEvent } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import {
  DocumentsSection,
  NewslettersSection,
  PreviewSection,
  ReferralsSection,
  RichTextSection,
  TagsSection,
} from '../../components/sections';
import { CloseIcon, MenuEventsIcon } from '../../components/svg';
import { useParams } from 'react-router-dom';
import { PreviewPageProps } from '../page-types';
import Error from '../../components/Error';
import { useApiQuery, useDesktop, useDownload, usePageFetch } from '../../hooks';
import qs from 'qs';
import { useUser } from '../../providers/UserProvider';
import React, { useEffect, useState } from 'react';
import AttendanceSwitch from './AttendanceSwitch';
import dayjs from 'dayjs';
import EventCard from '../event-search/EventCard';
import ShareButton from './ShareButton';
import classNames from 'classnames';
import EventDatesSection from '../../components/event/EventDatesSection';
import { computeDates, cover } from '../../utils/events';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { prependHttp } from '../../utils/utils';
import BackButton from '../../components/BackButton';
import Background from '../../components/Background';
import { ENV } from '../../utils/environment';

const EventPage = ({ preview }: PreviewPageProps) => {
  const { id } = useParams();
  const { fetch } = useUser();
  const desktop = useDesktop();
  const { doApiQuery } = useApiQuery();

  const query = qs.stringify(
    {
      preview: preview,
    },
    {
      encodeValuesOnly: true, // prettify url
    },
  );

  const { data, error } = usePageFetch<StrapiEvent>(`/api/events/${id}?${query}`);
  const [attendanceStatus, setAttendanceStatus] = useState<GrappAttendanceStatus>();
  const [agendaPopupOpen, setAgendaPopupOpen] = useState(false);

  const closeAgendaDialog = () => setAgendaPopupOpen(false);

  const { start, end, duration, past } = data
    ? computeDates(data)
    : {
        start: undefined,
        end: undefined,
        duration: undefined,
        past: undefined,
      };

  useEffect(() => {
    setAttendanceStatus(data?.publication.attendances[0]?.status);
  }, [data]);

  if (error) {
    return <Error message={error} />;
  }

  const updateAttendance = (status: GrappAttendanceStatus) =>
    doApiQuery("changement de statut d'inscription", async () => {
      const oldStatus = attendanceStatus;
      const response = await fetch(`/api/events/${id}/updateAttendance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status }),
      });

      if (response.ok) {
        setAttendanceStatus(status);
        if (
          oldStatus !== 'participate' &&
          oldStatus !== 'participateRemote' &&
          (status === 'participate' || status === 'participateRemote')
        ) {
          setAgendaPopupOpen(true);
        }
      }
      return response;
    });

  return (
    <>
      {preview && <PreviewSection />}
      <Header />
      {data && (
        <div id="eventPage" className={classNames(past ? 'past' : 'future')}>
          <div id="top">
            <div className="cover">
              <Background className="coverImage" file={cover(data)} />
            </div>
            <BackButton variant="dark" />
            <div className="content">
              <TagsSection tags={data.tags} />
              <div className="inner">
                <h1>
                  {data.name}
                  {preview && <span className="preview"> (prévisualisation)</span>}
                </h1>
                <div className="infos">
                  <ul>
                    <EventDatesSection start={start} end={end} />
                    {data?.address ? (
                      <li className="address">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${data?.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.address}
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {data?.publication.mode === 'remote' ? (
                      <li className="remote">
                        {data.webinar ? (
                          <a href={prependHttp(data.webinar)} target="_blank" rel="noreferrer">
                            En distanciel
                          </a>
                        ) : (
                          'En distanciel'
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {data?.publication.mode === 'both' ? (
                      <li className="both">
                        {data.webinar ? (
                          <a href={prependHttp(data.webinar)} target="_blank" rel="noreferrer">
                            Évènement mixte
                          </a>
                        ) : (
                          'Évènement mixte'
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
                <div className="date">
                  <div className="start">
                    <span className="day">{dayjs(data?.publication.start).format('DD')}</span>
                    <span className="month">{dayjs(data?.publication.start).format('MMM')}</span>
                  </div>
                  {duration && duration > 1 && <div className="duration">{duration} j</div>}
                </div>
                {data.publication.private && <div className="private">Sur invitation</div>}
                <div className="actions">
                  <AttendanceSwitch
                    past={past ?? false}
                    mode={data.publication.mode}
                    status={attendanceStatus}
                    onChange={updateAttendance}
                  />
                  <ShareButton event={data} />
                </div>
              </div>
            </div>
          </div>
          <div id="bottom">
            <main>
              {!desktop && (
                <>
                  {attendanceStatus === 'participate' && <AgendaButton data={data} />}
                  <ReferralsSection referrals={data.referrals} title="Les interlocuteurs" />
                </>
              )}
              <RichTextSection html={data.content} />
              <DocumentsSection
                documents={data.documents}
                title="Référentiel documentaire de l'évènement"
              />
            </main>
            <aside>
              {desktop && (
                <>
                  {attendanceStatus === 'participate' && <AgendaButton data={data} />}
                  <ReferralsSection referrals={data.referrals} title="Mes interlocuteurs" />
                </>
              )}
              <NewslettersSection newsletters={data.newsletters} />
            </aside>
          </div>
          {data?.suggestions?.length > 0 && (
            <div id="crossSelling">
              <div className="content">
                <h2>Autres évènements pouvant vous intéresser</h2>
                <ul className="list">
                  {data?.suggestions?.map((event) => (
                    <li className="item" key={event.id}>
                      <EventCard event={event} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <Dialog className="agendaDialog" open={agendaPopupOpen} onClose={closeAgendaDialog}>
            <div className="infoSuccess">
              <Button className="close" onClick={closeAgendaDialog}>
                {CloseIcon}
              </Button>
              <DialogTitle>
                Vous êtes maintenant inscrit à cet événement du {ENV.CLIENT_NAME} !
              </DialogTitle>
              <DialogContent>
                <AgendaButton data={data} />
              </DialogContent>
            </div>
          </Dialog>
        </div>
      )}
      <Footer />
    </>
  );
};

const AgendaButton = ({ data }: { data: StrapiEvent }) => {
  const { download } = useDownload();

  return (
    <Button
      onClick={() =>
        download({
          url: `/api/events/${data.id}/calendar`,
          name: 'evenement.ics',
        })
      }
      className="agenda"
    >
      {MenuEventsIcon} Ajouter à l'agenda
    </Button>
  );
};

export default EventPage;
