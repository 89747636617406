import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { MouseEventHandler } from 'react';
import { Copyright } from './Copyright';

type ErrorProps = {
  title?: string;
  message: string;
  onHomeClick?: MouseEventHandler;
};

const Error = ({ title = 'Erreur !', message, onHomeClick }: ErrorProps) => (
  <div id="errorPage" className="notFound">
    <main>
      <Link className="logo" to="/" />
      <Copyright />
    </main>
    <aside>
      <div className="hider">
        <div className="scroll">
          <div className="content">
            <div className="inner">
              <h1>{title}</h1>
              <p>{message}</p>
              <Button color="primary" component={Link} to="/" onClick={onHomeClick}>
                Retourner à l'accueil
              </Button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>
);

export default Error;
