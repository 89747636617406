import { string } from 'yup';

/** We use the same validation library than strapi. */
export const validateEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }
  return string().email().isValidSync(email);
};

/** Check that this string is not empty. */
export const validateNotBlank = (value?: string): boolean => {
  if (!value) {
    return false;
  }

  return Boolean(value.trim().length > 0);
};

/** Check that this has only numbers and spaces. */
export const validatePhone = (value?: string): boolean => {
  if (!validateNotBlank(value)) {
    return false;
  }

  return /^[ 0-9]*$/.test(value!);
};
