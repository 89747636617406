import { Autocomplete, InputBaseComponentProps } from '@mui/material';
import { usePageFetch } from '../hooks';
import { StrapiRank } from '../api/strapi';
import TextField from './TextField';
import * as React from 'react';
import { ReactNode } from 'react';

export type AutocompleteValue = {
  id: number;
  label: string;
};

type RankAutocompleteProps = {
  value: AutocompleteValue | null;
  label?: ReactNode;
  className?: string;
  onChange: (value: AutocompleteValue | null) => void;
  inputProps?: InputBaseComponentProps;
};

export const autocompleteValue = (value: StrapiRank | undefined | null): AutocompleteValue | null =>
  value ? { id: value.id, label: value.label } : null;

const getOptionLabel = (option: AutocompleteValue) => option.label ?? 'Inconnu';
const isOptionEqualToValue = (option: AutocompleteValue, value: AutocompleteValue) =>
  option.id === value.id;

const RankAutocomplete = ({
  value,
  label,
  className,
  onChange,
  inputProps,
}: RankAutocompleteProps) => {
  const { data: ranks = [] } = usePageFetch<StrapiRank[]>(`/api/ranks`);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          className={className}
          label={label}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
          }}
        />
      )}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      options={ranks.map((rank) => ({ id: rank.id, label: rank.label }))}
      onChange={(event, value) => onChange(value ?? null)}
      value={value}
    />
  );
};

export default RankAutocomplete;
