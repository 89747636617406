import { StrapiArborescence } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Error from '../../components/Error';
import { usePageFetch } from '../../hooks';
import BackButton from '../../components/BackButton';
import React from 'react';
import Background from '../../components/Background';

const ArborescencePage = () => {
  const { data, error } = usePageFetch<StrapiArborescence>('/api/arborescence');

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      <div id="arborescencePage">
        <div id="top">
          <BackButton variant="dark" />
          <div className="content">
            <h1>Mes adhésions</h1>
          </div>
        </div>
        <div id="bottom">
          <ul className="list">
            {data?.item
              .filter((item) => item.tag.pages.length)
              .map((item) => (
                <li className="item" key={item.title}>
                  <div className="arborescenceCard">
                    <div className="cover">
                      <Link to={`/tag/${item.tag.id}`}>
                        <Background file={item.tag.image ?? undefined}>
                          <span className="title">{item.title}</span>
                        </Background>
                      </Link>
                    </div>
                    <div className="content">
                      <div className="count">
                        Votre organisation adhère à{' '}
                        <strong>
                          {item.tag.pages.length} {item.tag.pages.length > 1 ? 'offres' : 'offre'}
                        </strong>{' '}
                        :
                      </div>
                      <div className={item.tag.pages.length > 5 ? 'pages truncate' : 'pages'}>
                        <ul>
                          {item.tag.pages.map((page) => (
                            <li key={page.id}>
                              <Link to={`/page/${page.id}`}>{page.title}</Link>
                            </li>
                          ))}
                        </ul>
                        {item.tag.pages.length > 5 && (
                          <div className="more">
                            + {item.tag.pages.length - 5}{' '}
                            {item.tag.pages.length - 5 > 1 ? 'autres offres' : 'autre offre'}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="all">
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/tag/${item.tag.id}`}
                      >
                        Voir toutes les offres
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArborescencePage;
