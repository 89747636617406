import { Civility, GrappContact } from '../../api/strapi';
import { Button, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { validateNotBlank, validatePhone } from '../../utils/validation';
import EmailTextField from '../../components/EmailTextField';
import PhoneTextField from '../../components/PhoneTextField';

type ContactFormProps = {
  /** Initial contact info */
  contact: GrappContact;
  onConfirm: (contact: GrappContact) => void;
  /** When the user says it is not its info */
  onDecline: () => void;
};

const ContactForm = ({ contact, onConfirm, onDecline }: ContactFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState(contact);
  const [showErrors, setShowErrors] = useState(false);

  const validPhone = validatePhone(value.phone);
  const validCivility = validateNotBlank(value.civility);
  const validFirstname = validateNotBlank(value.firstname);
  const validLastname = validateNotBlank(value.lastname);

  const handleSave = () => {
    if (!(validFirstname && validLastname && validPhone && validCivility)) {
      enqueueSnackbar('Les champs obligatoires doivent être complétés.', { variant: 'error' });
      setShowErrors(true);
    } else {
      onConfirm(value);
    }
  };

  return (
    <div className="formStep">
      <h1>Confirmation de vos informations de contact</h1>
      <div className="explanation">
        <p>
          Voici les informations de contact que nous avons pour vous. Merci de les vérifier et de
          nous indiquer le cas échéant s'il y a des modifications à y apporter.
        </p>
      </div>

      <EmailTextField
        value={value.email}
        className="mandatory"
        disabled
        label="Adresse e-mail / Identifiant"
      />
      <TextField
        value={value.civility ?? ''}
        className="mandatory"
        label="Civilité"
        select
        disabled={Boolean(contact.civility)}
        onChange={(event) =>
          setValue({
            ...value,
            civility: event.target.value as Civility,
          })
        }
        error={showErrors && !validCivility}
      >
        <MenuItem value={'Madame'}>Madame</MenuItem>
        <MenuItem value={'Monsieur'}>Monsieur</MenuItem>
      </TextField>
      <TextField
        value={value.firstname ?? ''}
        className="mandatory"
        disabled={Boolean(contact.firstname)}
        label="Prénom"
        onChange={(event) =>
          setValue({
            ...value,
            firstname: event.currentTarget.value,
          })
        }
        error={showErrors && !validFirstname}
      />
      <TextField
        value={value.lastname ?? ''}
        className="mandatory"
        disabled={Boolean(contact.lastname)}
        label="Nom"
        onChange={(event) =>
          setValue({
            ...value,
            lastname: event.currentTarget.value,
          })
        }
        error={showErrors && !validLastname}
      />
      <PhoneTextField
        value={value.phone ?? ''}
        label="Téléphone"
        className="mandatory"
        onChange={(event) =>
          setValue({
            ...value,
            phone: event.currentTarget.value,
          })
        }
        error={showErrors && !validPhone}
      />
      <div className="actions">
        <Button color="secondary" variant="contained" onClick={onDecline}>
          Ce n'est pas moi
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Continuer
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
