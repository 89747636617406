import React from 'react';
import { StrapiRichTextPage } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { RichTextSection } from '../../components/sections';
import Error from '../../components/Error';
import { usePageFetch } from '../../hooks';
import BackButton from '../../components/BackButton';

const AboutPage = () => {
  const { data, error } = usePageFetch<StrapiRichTextPage>('/api/about-page');

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      {data && (
        <div id="contentPage">
          <div id="bottom">
            <main>
              <div id="top">
                <BackButton />
                <div className="content">
                  <h1>À propos</h1>
                </div>
              </div>
              <RichTextSection html={data.content} />
            </main>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default AboutPage;
