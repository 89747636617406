import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { ContentMenu } from './ContentMenu';
import { Search } from './Search';
import { UserMenu } from './UserMenu';
import { MenuIcon, SearchIcon } from '../svg';
import { useOnboarding } from '../../providers/OnboardingProvider';
import { useDesktop, usePageFetch } from '../../hooks';
import { StrapiMenu } from '../../api/strapi';

export const Header = () => {
  const { data } = usePageFetch<StrapiMenu>('/api/menu');
  const [menuOpen, setMenuOpen] = useState(false);
  const { hasOnboarding, openPageOnboarding, openPageOnboardingIfNeeded } = useOnboarding();
  const desktop = useDesktop();

  useEffect(() => openPageOnboardingIfNeeded(), [openPageOnboardingIfNeeded]);

  const closeMenu = () => setMenuOpen(false);
  const openMenu = () => setMenuOpen(true);

  return (
    <header>
      <Button color="primary" className="menu" onClick={openMenu}>
        {MenuIcon}
        Menu
      </Button>
      <ContentMenu data={data} open={menuOpen} onClose={closeMenu} />
      <Link className="logo" to="/" />
      {desktop ? (
        <Search />
      ) : (
        <Link className="searchLink" color="secondary" to="/recherche">
          {SearchIcon}
        </Link>
      )}
      {hasOnboarding && <Button onClick={openPageOnboarding} color="secondary" className="help" />}
      {desktop && <UserMenu />}
    </header>
  );
};
