import React from 'react';
import { TextFieldProps } from '@mui/material';
import TextField from './TextField';

const PhoneTextField = (props: TextFieldProps) => (
  <TextField
    helperText="Seuls les chiffres et espaces sont autorisés."
    {...props}
    type="tel"
    inputMode="tel"
  />
);

export default PhoneTextField;
