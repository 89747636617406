import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useUser } from '../../providers/UserProvider';
import { useApiQuery, usePageFetch } from '../../hooks';
import { StrapiRequestConfiguration, UserContact } from '../../api/strapi';
import RankAutocomplete, { AutocompleteValue } from '../../components/RankAutocomplete';
import BackButton from '../../components/BackButton';
import { validatePhone } from '../../utils/validation';
import EmailTextField from '../../components/EmailTextField';
import PhoneTextField from '../../components/PhoneTextField';
import { ENV } from '../../utils/environment';

const ProfileParametersPage = () => {
  const { user } = useUser();

  const { data: requestConfiguration } = usePageFetch<StrapiRequestConfiguration>(
    '/api/request-configuration',
  );

  if (!user) {
    return null;
  }
  return (
    <>
      <Header />
      <div id="profileParametersPage">
        <div id="top">
          <BackButton />
          <div className="content">
            <h1>Mes paramètres</h1>
          </div>
        </div>
        <div id="bottom">
          <IdentitySection
            contact={user.contact}
            requestCategoryId={requestConfiguration?.contactChange?.id}
          />
          <ContactSection contact={user.contact} />
          <JobSection requestCategoryId={requestConfiguration?.contactDeparture?.id} />
          <NewsletterSection contact={user.contact} />
        </div>
      </div>
      <Footer />
    </>
  );
};

type IdentitySectionProps = {
  contact: UserContact;
  requestCategoryId?: number;
};

const IdentitySection = ({
  contact: { email, firstname, lastname, civility },
  requestCategoryId,
}: IdentitySectionProps) => (
  <section className="id">
    <h2>Mon identité</h2>
    <EmailTextField value={email} disabled label="Adresse e-mail / Identifiant" />
    <TextField value={civility} disabled label="Civilité" />
    <TextField value={firstname} disabled label="Prénom" />
    <TextField value={lastname} disabled label="Nom" />
    {requestCategoryId && (
      <div className="actions">
        <Button
          color="secondary"
          variant="contained"
          component={Link}
          to={`/contact?category=${requestCategoryId}`}
        >
          Demander une modification
        </Button>
      </div>
    )}
  </section>
);

type ContactSectionProps = {
  contact: UserContact;
};

const ContactSection = ({ contact }: ContactSectionProps) => {
  const { fetch, reloadUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { doApiQuery, ongoing } = useApiQuery();

  const [phone, setPhone] = useState(contact.phone ?? '');
  useEffect(() => {
    setPhone(contact.phone ?? '');
  }, [contact]);

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => setPhone(event.target.value);

  const phoneIsValid = validatePhone(phone);
  const canSave = Boolean(phoneIsValid && phone !== contact.phone);

  const handleSave = async () => {
    const success = await doApiQuery("l'enregistrement", () =>
      fetch('/api/users/me/contact', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          phone,
        }),
      }),
    );
    if (success) {
      enqueueSnackbar('Vos modifications ont été enregistrées', {
        variant: 'success',
      });
      reloadUser();
    }
  };

  return (
    <section className="contact">
      <h2>Mes informations de contact</h2>
      <PhoneTextField
        value={phone}
        label="Téléphone"
        className="mandatory"
        error={phone !== contact.phone && !phoneIsValid}
        onChange={handleChangePhone}
      />
      <div className="actions">
        <Button
          color="primary"
          variant="contained"
          disabled={!canSave || ongoing}
          onClick={handleSave}
        >
          Enregistrer
        </Button>
      </div>
    </section>
  );
};

type JobSectionProps = { requestCategoryId?: number };

const JobSection = ({ requestCategoryId }: JobSectionProps) => {
  const { user, account: userAccount, fetch, reloadUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { doApiQuery, ongoing } = useApiQuery();

  const [accountIndex, setAccountIndex] = useState(0);
  useEffect(() => {
    if (user && userAccount) {
      const userAccountIndex = user?.accounts.findIndex((a) => a.id === userAccount.id) ?? 0;
      setAccountIndex(userAccountIndex !== -1 ? userAccountIndex : 0);
    } else {
      setAccountIndex(0);
    }
    // Reset the active tab when the user/selected account changes, but not when reloading the user after saving.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount?.id, user?.username]);
  const account = user?.accounts[accountIndex];

  const [jobRank, setJobRank] = useState(account?.jobRank ?? null);
  const [jobTitle, setJobTitle] = useState(account?.jobTitle ?? '');
  useEffect(() => {
    setJobRank(account?.jobRank ?? null);
    setJobTitle(account?.jobTitle ?? '');
  }, [account, accountIndex]);

  const handleChangeRank = (newRank: AutocompleteValue | null) => setJobRank(newRank);
  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) =>
    setJobTitle(event.target.value);

  const canSave = Boolean(
    jobRank &&
      jobTitle?.length &&
      (jobRank?.id !== account?.jobRank?.id || jobTitle !== account?.jobTitle),
  );

  const handleSave = async () => {
    const success = await doApiQuery("l'enregistrement", () =>
      fetch(`/api/users/me/accounts/${account?.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jobRankId: jobRank?.id,
          jobTitle,
        }),
      }),
    );
    if (success) {
      enqueueSnackbar('Vos modifications ont été enregistrées', {
        variant: 'success',
      });
      reloadUser();
    }
  };

  if (!user || !account) {
    return null;
  }
  return (
    <section className="contact">
      <h2>Mes informations professionnelles</h2>
      <ul className="tabs">
        {user.accounts.map((account, index) => (
          <li key={index}>
            <Button
              className={clsx(accountIndex === index && 'active')}
              onClick={() => setAccountIndex(index)}
            >
              {account.name}
            </Button>
          </li>
        ))}
      </ul>
      <div className="content">
        <RankAutocomplete
          value={jobRank}
          label="Fonction"
          className="mandatory"
          onChange={handleChangeRank}
        />
        <TextField
          value={jobTitle}
          label="Titre"
          className="mandatory"
          onChange={handleChangeTitle}
        />
        <div className="actions">
          {account.admin && <div>Vous êtes administrateur de cette organisation</div>}
          <Button
            color="primary"
            variant="contained"
            disabled={!canSave || ongoing}
            onClick={handleSave}
          >
            Enregistrer
          </Button>
          {requestCategoryId && (
            <Button
              color="secondary"
              variant="contained"
              component={Link}
              to={`/contact?category=${requestCategoryId}`}
            >
              J'ai quitté cette organisation
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

type AcceptedEmailingProps = { contact: UserContact };

const NewsletterSection = ({ contact }: AcceptedEmailingProps) => {
  const { fetch, reloadUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { doApiQuery, ongoing } = useApiQuery();

  const [acceptedEmailing, setAcceptedEmailing] = useState(contact.acceptedEmailing);
  useEffect(() => {
    setAcceptedEmailing(contact.acceptedEmailing);
  }, [contact]);

  const handleSave = async (checked: boolean) => {
    const success = await doApiQuery("l'enregistrement", () =>
      fetch('/api/users/me/emailing', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          acceptedEmailing: checked,
        }),
      }),
    );
    if (success) {
      enqueueSnackbar(
        checked
          ? `Vous avez bien été inscrit(e) aux communications du ${ENV.CLIENT_NAME}`
          : `Vous avez bien été désinscrit(e) aux communications du ${ENV.CLIENT_NAME}`,
        {
          variant: 'success',
        },
      );
      reloadUser();
    }
  };

  const handleChangeAcceptedEmailing = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAcceptedEmailing(checked);
    handleSave(checked).then();
  };

  return (
    <section className="newsletter">
      <h2>Mes lettres d'information</h2>
      <FormControlLabel
        control={
          <Checkbox
            checked={acceptedEmailing}
            onChange={handleChangeAcceptedEmailing}
            disabled={ongoing}
          />
        }
        label={`J'accepte de recevoir les communications du ${ENV.CLIENT_NAME}`}
      />
    </section>
  );
};

export default ProfileParametersPage;
