import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Civility, GrappContact, GrappJob, StrapiAccess, StrapiUser } from '../../api/strapi';
import { useApiQuery, usePageFetch } from '../../hooks';
import ContactForm from './ContactForm';
import JobForm from './JobForm';
import PasswordForm from './PasswordForm';
import { Button, CircularProgress } from '@mui/material';
import { Copyright } from '../../components/Copyright';
import { useUser } from '../../providers/UserProvider';
import { ENV } from '../../utils/environment';

type ContactStep = {
  type: 'contact';
  contact: GrappContact;
};

type JobStep = {
  type: 'job';
  job: GrappJob;
};

type PasswordStep = {
  type: 'password';
};

type EndStep = {
  type: 'end';
  subtype: EndStepSubtype;
};
type EndStepSubtype = 'invalid' | 'done' | 'notme' | 'jobsDeclined' | 'error';

type UserConfirmationStep = ContactStep | JobStep | PasswordStep | EndStep;

type ContactFormResult = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  civility?: Civility;
};

type FormResults = {
  password?: string;
  validatedJobs: {
    id: number;
    rankId: number;
    title: string;
  }[];
  declinedJobs: {
    id: number;
  }[];
  contact?: ContactFormResult;
};

const UserRegistrationPage = () => {
  const { token } = useParams();
  const { doApiQuery, ongoing } = useApiQuery();
  const [jwt, setJwt] = useState<string>();
  const { error, data } = usePageFetch<StrapiUser>(`/api/register/${token}`);
  useEffect(() => {
    if (error) {
      setCurrentStep({
        type: 'end',
        subtype: 'invalid',
      });
    }
  }, [error]);

  const [currentStep, setCurrentStep] = useState<UserConfirmationStep>();
  const [formResults, setFormResults] = useState<FormResults>({
    declinedJobs: [],
    validatedJobs: [],
  });

  const formSteps = useMemo<UserConfirmationStep[]>(() => {
    if (!data) {
      return [];
    }

    const value: UserConfirmationStep[] = [];
    if (!data.confirmed) {
      value.push({ type: 'contact', contact: data.contact });
    }
    data.accesses.forEach((access) =>
      value.push({
        type: 'job',
        job: access.job,
      }),
    );

    if (!data.confirmed) {
      value.push({ type: 'password' });
    }
    return value;
  }, [data]);

  useEffect(() => {
    if (formSteps.length > 0) {
      setCurrentStep(formSteps[0]);
    }
  }, [formSteps]);

  const nextStep = async (newFormResults: FormResults) => {
    if (ongoing) {
      return;
    }

    setFormResults(newFormResults);
    if (!currentStep) {
      return;
    }

    const noValidatedJobs = newFormResults.validatedJobs.length === 0;

    const currentIndex = formSteps.indexOf(currentStep!);
    const nextStep = formSteps[currentIndex + 1];
    if (!nextStep) {
      // We validated the last form, moving on submitting. Note that even if all jobs were validated
      const success = await submitRegistration(newFormResults);
      setCurrentStep({
        type: 'end',
        subtype: success ? 'done' : 'error',
      });
    } else if (noValidatedJobs && nextStep.type === 'password') {
      // The user has not validated any access, so we fallback to decline instead of showing the password form.
      await declineRegistration(true, newFormResults.contact);
    } else {
      setCurrentStep(nextStep);
    }
  };

  const declineRegistration = useCallback(
    async (declineJobs: boolean, contact?: ContactFormResult) => {
      const success = await doApiQuery("l'enregistrement", () =>
        fetch(`/api/register/${token}/decline`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ declineJobs, contact }),
        }),
      );

      let subtype: EndStepSubtype;
      if (!success) {
        subtype = 'error';
      } else if (declineJobs) {
        subtype = 'jobsDeclined';
      } else {
        subtype = 'notme';
      }
      setCurrentStep({
        type: 'end',
        subtype,
      });
    },
    [doApiQuery, token],
  );

  const submitRegistration = useCallback(
    async (newFormResults) => {
      if (ongoing) {
        return false;
      }
      return await doApiQuery("l'enregistrement", async () => {
        const response = await fetch(`/api/register/${token}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newFormResults),
        });
        if (response.ok) {
          const body: { jwt: string } = await response.json();
          setJwt(body.jwt);
        }
        return response;
      });
    },
    [doApiQuery, token, ongoing],
  );

  if (!currentStep || ongoing) {
    return (
      <PageLayout>
        <div className="loader">
          <CircularProgress />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {currentStep.type === 'contact' && (
        <ContactForm
          contact={currentStep.contact}
          onConfirm={async (contact) => {
            await nextStep({
              ...formResults,
              contact: {
                id: contact.id,
                email: contact.email.trim(),
                phone: contact.phone.trim(),
                civility: contact.civility,
                firstname: contact.firstname.trim(),
                lastname: contact.lastname.trim(),
              },
            });
          }}
          onDecline={() => declineRegistration(false)}
        />
      )}
      {currentStep.type === 'job' && (
        <JobForm
          job={currentStep.job}
          onConfirm={async (job) => {
            const newFormResults = {
              ...formResults,
              validatedJobs: [
                ...formResults.validatedJobs,
                {
                  id: job.id,
                  rankId: job.rank!.id,
                  title: job.title.trim(),
                },
              ],
            };
            await nextStep(newFormResults);
          }}
          onDecline={async () => {
            const newFormResults = {
              ...formResults,
              declinedJobs: [
                ...formResults.declinedJobs,
                {
                  id: currentStep.job.id,
                },
              ],
            };
            await nextStep(newFormResults);
          }}
        />
      )}
      {currentStep.type === 'password' && (
        <PasswordForm
          onConfirm={async (password) => {
            const newFormResults = {
              ...formResults,
              password,
            };
            await nextStep(newFormResults);
          }}
        />
      )}
      {currentStep.type === 'end' && (
        <>
          {currentStep.subtype === 'done' && (
            <DoneStep
              validatedJobIds={formResults.validatedJobs.map((job) => job.id)}
              accesses={data?.accesses ?? []}
              jwt={jwt}
            />
          )}
          {currentStep.subtype === 'invalid' && (
            <div className="errorStep">
              <h1>Erreur !</h1>
              <p>Ce lien de confirmation n'est pas valide ou a expiré.</p>
              <div className="actions">
                <Button color="primary" variant="contained" to="/" component={Link}>
                  Retourner à l'espace adhérents
                </Button>
              </div>
            </div>
          )}
          {currentStep.subtype === 'error' && (
            <div className="errorStep">
              <h1>Erreur !</h1>
              <p>
                Une erreur est survenue lors de la finalisation de votre inscription. Veuillez
                réessayer plus tard.
              </p>
              <div className="actions">
                <Button color="primary" variant="contained" to="/" component={Link}>
                  Retourner à l'espace adhérents
                </Button>
              </div>
            </div>
          )}
          {currentStep.subtype === 'jobsDeclined' && (
            <div className="errorStep">
              <h1>Aucun accès créé.</h1>
              <p>
                Les informations que vous avez renseignées ne nous permettent pas de vous créer un
                accès à l'espace adhérents. Un agent du {ENV.CLIENT_NAME} vous recontactera
                prochainement pour finaliser votre inscription.
              </p>
              <div className="actions">
                <Button color="primary" variant="contained" to="/" component={Link}>
                  Retourner à l'espace adhérents
                </Button>
              </div>
            </div>
          )}
          {currentStep.subtype === 'notme' && (
            <div className="errorStep">
              <h1>Aucun accès créé.</h1>
              <p>
                Vous avez déclaré que nos informations vous regardant ne correspondent pas à votre
                profil. Un agent du {ENV.CLIENT_NAME} vous recontactera prochainement pour rectifier
                ces informations et finaliser votre inscription.
              </p>
              <div className="actions">
                <Button color="primary" variant="contained" to="/" component={Link}>
                  Retourner à l'espace adhérents
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </PageLayout>
  );
};

type DoneStepProps = {
  validatedJobIds: number[];
  accesses: StrapiAccess[];
  jwt?: string;
};

const DoneStep = ({ validatedJobIds, accesses, jwt }: DoneStepProps) => {
  const navigate = useNavigate();
  const { saveJwt, reloadUser } = useUser();
  const gotoHome = async () => {
    if (jwt) {
      saveJwt(jwt);
    }
    await reloadUser();
    navigate('/');
  };

  return (
    <div className="validatedStep">
      <h1>C'est enregistré !</h1>
      <p>
        Vous avez validé vos accès pour les organisations suivantes :
        {accesses
          .filter((access) => validatedJobIds.includes(access.job.id))
          .map((access) => (
            <div key={access.id}>{access.job.account.name}</div>
          ))}
      </p>
      <div className="actions">
        <Button color="primary" variant="contained" onClick={gotoHome}>
          Retourner à l'espace adhérents
        </Button>
      </div>
    </div>
  );
};

const PageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div id="confirmationPage">
      <main>
        <Link className="logo" to="/" />
        <Copyright />
      </main>
      <aside>
        <div className="hider">
          <div className="scroll">
            <div className="content">
              <div className="inner">{children}</div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default UserRegistrationPage;
