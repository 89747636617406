import { useUser } from './providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { StrapiFile } from './api/strapi';
import * as Sentry from '@sentry/react';
import { useErrorHandler } from 'react-error-boundary';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { downloadBlob } from './utils/utils';

function isFile(params: DownloadParams): params is StrapiFile {
  return (params as StrapiFile).id !== undefined;
}

export const usePageFetch = <T>(query: string, skip: boolean = false) => {
  const { fetch } = useUser();
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<T | undefined>();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (skip) {
      return;
    }

    setData(undefined);
    const fetchData = async () => {
      try {
        const response = await fetch(query);
        if (response.ok) {
          const body: T = await response.json();
          setData(body);
        } else if (response.status === 404) {
          setError("La page à laquelle vous essayez d'accéder n'existe pas.");
        } else if (response.status === 403) {
          setError("Vous n'avez pas les droits nécessaires pour accéder à cette page.");
        } else {
          setError('Erreur lors du chargement de la page. Veuillez réessayer ultérieurement.');
          Sentry.captureMessage('Erreur lors du chargement de la page');
        }
      } catch (e) {
        setError('Erreur lors du chargement de la page. Veuillez réessayer ultérieurement.');
        Sentry.captureException(e);
      }
    };
    fetchData().catch(handleError);
  }, [query, fetch, handleError, skip]);

  return {
    error,
    data,
  };
};

export const useApiQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHandler();
  const [ongoing, setOngoing] = useState(false);

  const doApiQuery = useCallback(
    async (label: string, query: () => Promise<Response>): Promise<boolean> => {
      setOngoing(true);
      try {
        const response = await query();
        if (!response.ok) {
          enqueueSnackbar("Une erreur s'est produite.", { variant: 'error' });
          Sentry.captureMessage(`Erreur ${response.status} lors de ${label}.`);
          return false;
        }
        return true;
      } catch (e) {
        enqueueSnackbar("Une erreur s'est produite.", { variant: 'error' });
        Sentry.captureException(e);
        handleError(e);
        return false;
      } finally {
        setOngoing(false);
      }
    },
    [enqueueSnackbar, handleError],
  );
  return { doApiQuery, ongoing };
};

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      window.clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

type DownloadParams =
  | StrapiFile
  | {
      url: string;
      name: string;
    };

export const useDownload = () => {
  const { fetch } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [ongoing, setOngoing] = useState(false);

  const download = useCallback(
    async (params: DownloadParams) => {
      let url;
      let name;
      if (isFile(params)) {
        url = `/api/media/${params.hash}${params.ext};`;
        name = `${params.name}`;
        if (!name.endsWith(params.ext)) {
          name += params.ext;
        }
      } else {
        url = params.url;
        name = params.name;
      }

      setOngoing(true);
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        downloadBlob(blob, name);
      } else {
        enqueueSnackbar("Une erreur s'est produite lors du téléchargement de votre fichier.", {
          variant: 'error',
        });
        Sentry.captureMessage(
          `Erreur ${response.status} lors de la récupération du fichier ${url}`,
        );
      }
      setOngoing(false);
    },
    [fetch, enqueueSnackbar],
  );

  return { download, ongoing };
};

/**
 * Test whether the screen has a mobile or tablet resolution.
 */
export const useMobileOrTablet = () => useMediaQuery('(max-width: 1080px)');

/**
 * Test whether the screen has a desktop resolution.
 */
export const useDesktop = () => useMediaQuery('(min-width: 1081px)');
