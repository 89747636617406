import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../hooks';
import TextField from '../../components/TextField';

type AccountAccessSearchProps = {
  initialFilter: string;
  onFilterChange: (filter: string) => void;
};

const AccountAccessSearch = ({ initialFilter, onFilterChange }: AccountAccessSearchProps) => {
  const [filter, setFilter] = useState<string>(initialFilter);
  const debouncedFilter = useDebounce(filter, 200);

  useEffect(() => {
    onFilterChange(debouncedFilter);
  }, [debouncedFilter, onFilterChange]);

  return (
    <TextField
      className="search"
      placeholder="Recherchez un utilisateur"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
};

export default AccountAccessSearch;
