import React, { useEffect, useState } from 'react';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { useUser } from '../../providers/UserProvider';
import { StrapiAccess, StrapiAccessUpdateCommand } from '../../api/strapi';
import { useApiQuery, usePageFetch } from '../../hooks';
import Error from '../../components/Error';
import { useSnackbar } from 'notistack';
import AccountAccessTable from './AccountAccessTable';
import AccountAccessSearch from './AccountAccessSearch';
import AccountInvite from './AccountInvite';
import BackButton from '../../components/BackButton';

const AccountAccessPage = () => {
  const { fetch, user, reloadUser } = useUser();

  const [accesses, setAccesses] = useState<StrapiAccess[]>([]);
  const { data, error } = usePageFetch<StrapiAccess[]>('/api/accesses');
  const [filter, setFilter] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { doApiQuery } = useApiQuery();

  useEffect(() => {
    setAccesses(data || []);
  }, [data]);

  const disableAccess = (id: number) =>
    doApiQuery("la désactivation d'un accès", async () => {
      const response = await fetch(`/api/accesses/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            status: 'disabled',
          },
        }),
      });
      if (response.ok) {
        accesses.filter((access) => access.id === id)[0].status = 'disabled';
        setAccesses([...accesses]);
      }
      return response;
    });

  const enableAccess = (id: number) =>
    doApiQuery("la réactivation d'un accès", async () => {
      const response = await fetch(`/api/accesses/${id} `, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            status: 'active',
          },
        }),
      });
      if (response.ok) {
        accesses.filter((access) => access.id === id)[0].status = 'active';
        setAccesses([...accesses]);
      }
      return response;
    });

  const updateAccess = (command: StrapiAccessUpdateCommand) =>
    doApiQuery("la mise à jour d'un accès", async () => {
      const response = await fetch(`/api/accesses/${command.id} `, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            id: command.id,
            jobRankId: command.jobRank.id,
            jobTitle: command.jobTitle,
            admin: command.admin,
          },
        }),
      });
      if (response.ok) {
        const access = accesses.filter((access) => access.id === command.id)[0];
        access.job.title = command.jobTitle;
        access.job.rank = command.jobRank
          ? {
              id: command.jobRank.id,
              label: `${command.jobRank.label}`,
              updatedAt: '',
              createdAt: '',
            }
          : null;
        access.admin = command.admin;
        setAccesses([...accesses]);

        if (user && access.user.username === user.username) {
          reloadUser();
        }
      }
      return response;
    });

  const deleteAccess = (id: number) =>
    doApiQuery("la suppression d'un accès", async () => {
      const response = await fetch(`/api/accesses/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const newAccesses = accesses.filter((access) => access.id !== id);
        setAccesses(newAccesses);
      }
      return response;
    });

  const resendInvitation = (id: number) =>
    doApiQuery("l'envoi d'une relance", async () => {
      const response = await fetch(`/api/accesses/${id}/resendInvitation`, {
        method: 'POST',
      });
      if (response.ok) {
        enqueueSnackbar('une nouvelle invitation a été envoyée.', { variant: 'success' });
      }
      return response;
    });

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      <div id="accountAccessPage">
        <BackButton />
        <div id="top">
          <h1>
            <span>Gestion des accès </span>
            <span>de mon organisation</span>
          </h1>
          <div className="options">
            <AccountAccessSearch initialFilter={filter} onFilterChange={setFilter} />
            <AccountInvite
              onInvite={(invited) => {
                setFilter('');
                setAccesses([...accesses, ...invited]);
              }}
            />
          </div>
        </div>
        <div id="bottom">
          <AccountAccessTable
            accesses={accesses}
            filter={filter}
            onDelete={deleteAccess}
            onDisable={disableAccess}
            onEnable={enableAccess}
            onResendInvitation={resendInvitation}
            onUpdate={updateAccess}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountAccessPage;
