import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { useErrorHandler } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';

import { User, UserAccount } from '../api/strapi';

const ACCOUNT_ID_HEADER_NAME: string = 'x-account-id';

export type UserInfo = {
  isAuthenticated: boolean;
  user?: User;
  account?: UserAccount;
  selectAccount: (account: UserAccount) => void;
  login: (identifier: string, password: string) => Promise<void>;
  logout: (saveFrom?: boolean) => void;
  saveJwt: (newJwt?: string) => void;
  fetch: typeof fetch;
  reloadUser: () => void;
};

const STORAGE_KEY_JWT = 'sipperecJwt';
const STORAGE_KEY_USER = 'sipperecUser';
const STORAGE_KEY_ACCOUNT = 'sipperecAccount';

const UserContext = createContext<UserInfo | undefined>(undefined);

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [jwt, setJwt] = useState(() => localStorage.getItem(STORAGE_KEY_JWT) ?? undefined);
  const [user, setUser] = useState(() => {
    const jsonUser = localStorage.getItem(STORAGE_KEY_USER);
    return jsonUser ? (JSON.parse(jsonUser) as User) : undefined;
  });
  const [account, setAccount] = useState(() => {
    const jsonAccount = localStorage.getItem(STORAGE_KEY_ACCOUNT);
    return jsonAccount ? (JSON.parse(jsonAccount) as UserAccount) : undefined;
  });
  const [credentialsValidated, setCredentialsValidated] = useState(false);
  const handleError = useErrorHandler();

  const saveJwt = (newJwt?: string) => {
    setJwt(newJwt);
    if (newJwt) {
      localStorage.setItem(STORAGE_KEY_JWT, newJwt);
    } else {
      localStorage.removeItem(STORAGE_KEY_JWT);
    }
  };

  const saveUser = (newUser?: User) => {
    setUser(newUser);
    if (newUser) {
      localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(newUser));

      // If the selected account is unset or no longer compatible, reset it to the first one for the user.
      const accountFromUser = account
        ? newUser.accounts.find((item) => item.id === account.id)
        : undefined;
      const isCurrentAccountCompatible = account && (newUser.fullAccess || accountFromUser);
      if (!isCurrentAccountCompatible) {
        saveAccount(newUser.accounts?.length ? newUser.accounts[0] : undefined);
      } else if (accountFromUser) {
        saveAccount(accountFromUser);
      }
    } else {
      localStorage.removeItem(STORAGE_KEY_USER);
    }
  };

  const saveAccount = (newAccount?: UserAccount) => {
    setAccount(newAccount);
    if (newAccount) {
      localStorage.setItem(STORAGE_KEY_ACCOUNT, JSON.stringify(newAccount));
    } else {
      localStorage.removeItem(STORAGE_KEY_ACCOUNT);
    }
  };

  const selectAccount = (newAccount: UserAccount) => {
    saveAccount(user?.accounts.find((a) => a.id === newAccount.id) ?? newAccount);
    navigate('/');
  };

  const login: UserInfo['login'] = async (identifier, password) => {
    let response: Response;
    try {
      response = await fetch('/api/auth/local', {
        method: 'POST',
        body: new URLSearchParams({ identifier: identifier.toLowerCase(), password }),
      });
    } catch (e) {
      Sentry.captureException(e);
      throw new Error('Erreur inconnue');
    }
    if (response.ok) {
      const data = await response.json();
      setCredentialsValidated(true);
      saveUser(data.user);
      saveJwt(data.jwt);
    } else if (response.status === 400) {
      throw new Error('Adresse ou mot de passe inconnu');
    } else {
      throw new Error('Erreur inconnue');
    }
  };

  const logout: UserInfo['logout'] = useCallback((saveFrom?) => {
    saveJwt();
    saveUser();
    setCredentialsValidated(false);

    // Reinitialize an external account on implicit/explicit logout
    const isCurrentAccountExternal =
      account && user?.accounts?.every((item) => item.id !== account.id);
    if (isCurrentAccountExternal) {
      saveAccount();
    }

    if (saveFrom) {
      navigate('/connexion', { state: { from: location } });
    } else {
      navigate('/connexion');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authenticatedFetch: typeof fetch = useCallback(
    async (input, init) => {
      const fetchInit: RequestInit = {
        ...init,
      };
      if (jwt) {
        const headers = new Headers(fetchInit.headers);
        headers.append('Authorization', `Bearer ${jwt}`);
        if (account?.id) {
          headers.append(ACCOUNT_ID_HEADER_NAME, account.id.toString());
        }
        fetchInit.headers = headers;
      }

      const response = await fetch(input, fetchInit);
      if (response.status === 401) {
        logout(true);
      }
      return response;
    },
    [account?.id, jwt, logout],
  );

  const reloadUser = async () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch('/api/users/me', { headers });
    if (!response.ok) {
      if (response.status === 401) {
        logout(true);
      }
      return;
    }

    const userDetails: User = await response.json();
    saveUser(userDetails);
    setCredentialsValidated(true);
  };

  // Initialize User from JWT
  useEffect(() => {
    if (jwt && !credentialsValidated) {
      reloadUser().catch(handleError);
    }
    // We don't want to listen on account changes as it is only there to check if we can keep its info.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, credentialsValidated]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: Boolean(jwt),
        user: credentialsValidated ? user : undefined,
        account,
        selectAccount,
        login,
        logout,
        saveJwt,
        reloadUser,
        fetch: authenticatedFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserInfo => {
  const userInfo = useContext(UserContext);
  if (userInfo === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return userInfo;
};
