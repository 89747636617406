import Error from '../../components/Error';
import { useUser } from '../../providers/UserProvider';

const DisabledAccountPage = () => {
  const { logout } = useUser();
  return (
    <Error
      title="Accès désactivés"
      message="Veuillez vous rapprocher de votre administrateur si vous voulez les réactiver."
      onHomeClick={() => logout()}
    />
  );
};

export default DisabledAccountPage;
