import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import { StrapiAccessSummary, StrapiAccessUpdateCommand } from '../../api/strapi';
import RankAutocomplete, {
  AutocompleteValue,
  autocompleteValue,
} from '../../components/RankAutocomplete';
import EmailTextField from '../../components/EmailTextField';

type AccountAccessEditProps = {
  access: StrapiAccessSummary;
  onUpdate: (access: StrapiAccessUpdateCommand) => Promise<boolean>;
};

const AccountAccessEdit = ({ access, onUpdate }: AccountAccessEditProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const [jobRank, setJobRank] = useState(autocompleteValue(access.jobRank));
  const [jobTitle, setJobTitle] = useState(access.jobTitle);
  const [admin, setAdmin] = useState(access.admin);

  useEffect(() => {
    setJobRank(autocompleteValue(access.jobRank));
    setJobTitle(access.jobTitle);
    setAdmin(access.admin);
  }, [access, open]);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleChangeRank = (newRank: AutocompleteValue | null) => setJobRank(newRank);
  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) =>
    setJobTitle(event.target.value);
  const handleChangeAdmin = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    setAdmin(checked);

  const canSave = Boolean(
    jobRank &&
      jobTitle?.length &&
      (jobRank?.id !== access.jobRank?.id ||
        jobTitle !== access.jobTitle ||
        admin !== access.admin),
  );
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    if (!jobRank) {
      // jobRank is defined because of the previous canSave check, but the compiler does not know it
      return;
    }
    setSaving(true);
    const success = await onUpdate({ id: access.id, jobRank, jobTitle, admin });
    setSaving(false);
    if (success) {
      handleCloseDialog();
    }
  };

  return (
    <>
      <Tooltip placement="top" arrow title="Modifier">
        <Button className="edit" onClick={handleOpenDialog} />
      </Tooltip>
      <Dialog className="editProfileDialog" open={open} onClose={handleCloseDialog}>
        <DialogTitle>Modifier un utilisateur</DialogTitle>
        <DialogContent>
          <EmailTextField value={access.email} disabled label="Adresse e-mail / Identifiant" />
          <TextField value={access.civility} disabled label="Civilité" />
          <TextField value={access.firstName} disabled label="Prénom" />
          <TextField value={access.lastName} disabled label="Nom" />
          <RankAutocomplete
            value={jobRank}
            label="Fonction"
            className="mandatory"
            onChange={handleChangeRank}
          />
          <TextField
            value={jobTitle}
            label="Titre"
            className="mandatory"
            onChange={handleChangeTitle}
          />
          <div className="admin">
            <FormControlLabel
              control={<Checkbox checked={admin} onChange={handleChangeAdmin} />}
              label="Administrateur espace adhérents de l’organisation"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDialog}>
            Annuler
          </Button>
          <Button disabled={!canSave || saving} onClick={handleSave}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountAccessEdit;
