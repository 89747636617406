import { useState } from 'react';
import { StrapiLandingPage } from '../../api/strapi';
import LoginForm from './LoginForm';
import AccessRequest from './AccessRequest';
import { Button, Drawer } from '@mui/material';
import { BackIcon } from '../../components/svg';
import { useMobileOrTablet, usePageFetch } from '../../hooks';
import { Copyright } from '../../components/Copyright';
import { useSearchParams } from 'react-router-dom';
import { ENV } from '../../utils/environment';

const LandingPage = () => {
  const [searchParams] = useSearchParams();
  const openAccessPopup = searchParams.get('accessPopup') === 'open';

  const { data } = usePageFetch<StrapiLandingPage>('/api/landing-page');
  const mobileOrTablet = useMobileOrTablet();

  const showBannerDate = new Date(data?.showBannerUntil ?? '');
  const showBanner = data?.banner && showBannerDate > new Date();

  const [open, setOpen] = useState<boolean>(false);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  if (!data) {
    return null;
  }

  const hasCover = Boolean(data.cover);
  return (
    <>
      {showBanner && <div id="informationBanner">{data.banner}</div>}
      <div id="landingPage">
        <main style={hasCover ? { backgroundImage: `url(/api/landing-page/cover)` } : undefined}>
          <div className="presentation">
            <div className="logo" />
            <h1>{data.title ?? `Bienvenue sur l'espace adhérents du ${ENV.CLIENT_NAME}`}</h1>
            <p>{data.description}</p>
          </div>
          {mobileOrTablet && (
            <div className="actions">
              <Button className="login" onClick={openMenu}>
                Se connecter
              </Button>
              <AccessRequest data={data} openOnLoad={openAccessPopup} />
            </div>
          )}
          <div className="footer">
            <Copyright />
          </div>
        </main>
        {mobileOrTablet ? (
          <Drawer id="loginMenu" anchor="right" open={open} onClose={() => setOpen(false)}>
            <div className="hider">
              <div className="scroll">
                <div className="content">
                  <Button className="close" onClick={closeMenu}>
                    {BackIcon}Retour
                  </Button>
                  <div className="inner">
                    <LoginForm />
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        ) : (
          <aside>
            <div className="hider">
              <div className="scroll">
                <div className="content">
                  <div className="inner">
                    <section className="login">
                      <LoginForm />
                    </section>
                    <div className="or">
                      <span>ou</span>
                    </div>
                    <AccessRequest data={data} openOnLoad={openAccessPopup} />
                  </div>
                </div>
              </div>
            </div>
          </aside>
        )}
      </div>
    </>
  );
};

export default LandingPage;
