import { GrappJob } from '../../api/strapi';
import { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import RankAutocomplete from '../../components/RankAutocomplete';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { validateNotBlank } from '../../utils/validation';

type JobFormProps = {
  /** Initial job info. */
  job: GrappJob;
  onConfirm: (job: GrappJob) => void;
  /** When the user says it is not affiliated with this account */
  onDecline: () => void;
};

const JobForm = ({ job, onConfirm, onDecline }: JobFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState(job);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setValue(job);
  }, [job]);

  const validRank = Boolean(value.rank?.id);
  const validTitle = validateNotBlank(value.title);

  const handleSave = () => {
    if (!(validRank && validTitle)) {
      enqueueSnackbar('Les champs obligatoires doivent être complétés.', { variant: 'error' });
      setShowErrors(true);
    } else {
      onConfirm(value);
    }
  };

  const accountName = job.account.name;
  const source = job.access.source;

  return (
    <div className="formStep">
      <h1>
        <strong>{accountName}</strong>
      </h1>
      <div className="explanation">
        {source === 'grapp' && (
          <p>
            Voici les informations professionnelles que nous avons pour vous au sein de
            l'organisation <strong>{accountName}</strong>. Merci de les vérifier et de nous indiquer
            le cas échéant s'il y a des modifications à y apporter.
          </p>
        )}
        {source === 'domain' && (
          <p>
            Votre adresse e-mail correspond à l’organisation suivante :{' '}
            <strong>{accountName}</strong>. Veuillez préciser votre fonction et votre titre associés
            à cette organisation. Si vous n’êtes pas lié à cette organisation, cliquez sur le bouton
            correspondant.
          </p>
        )}
        {source === 'invitation' && (
          <p>
            Vous avez été invité à rejoindre l'organisation suivante <strong>{accountName}</strong>.
            Veuillez préciser votre fonction et votre titre associés à cette organisation. Si vous
            n’êtes pas lié à cette organisation, cliquez sur le bouton correspondant.
          </p>
        )}
      </div>{' '}
      <RankAutocomplete
        value={
          (value.rank && {
            id: value.rank.id,
            label: value.rank.label,
          }) ??
          null
        }
        label="Fonction au sein de l'organisation"
        className="mandatory"
        onChange={(rank) =>
          setValue({
            ...value,
            rank: rank
              ? {
                  id: rank.id,

                  label: rank.label,
                  createdAt: '',
                  updatedAt: '',
                }
              : null,
          })
        }
        inputProps={{
          className: clsx({ error: showErrors && !validRank }),
        }}
      />
      <TextField
        value={value.title ?? ''}
        label="Titre au sein de l'organisation"
        className="mandatory"
        onChange={(event) =>
          setValue({
            ...value,
            title: event.currentTarget.value,
          })
        }
        error={showErrors && !validTitle}
      />
      <div className="actions">
        <Button color="secondary" variant="contained" onClick={onDecline}>
          {source === 'grapp'
            ? "J'ai quitté cette organisation"
            : 'Ne pas rejoindre cette organisation'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Rejoindre l'organisation
        </Button>
      </div>
    </div>
  );
};

export default JobForm;
