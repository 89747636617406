import { CartoucheMetric } from '../../components/cartouches';
import { Button } from '@mui/material';
import { StrapiHomeMetrics } from '../../api/strapi';

type HomeMetricsSectionProps = {
  metrics?: StrapiHomeMetrics[];
};

const HomeMetricsSection = ({ metrics }: HomeMetricsSectionProps) => {
  // Show only the first 4 metrics with a non-zero value
  const filteredMetrics =
    metrics
      ?.filter((metric) =>
        metric.metric.accountMetrics?.some((accountMetric) => accountMetric.value),
      )
      .slice(0, 4) ?? [];
  if (filteredMetrics.length === 0) {
    return null;
  }

  return (
    <section className="metrics">
      <h2>Mes indicateurs</h2>
      {filteredMetrics.length > 0 && (
        <ul
          data-size={filteredMetrics.length}
          data-parity={filteredMetrics.length % 2 === 0 ? 'even' : 'odd'}
        >
          {filteredMetrics.map((metric, index) => (
            <li key={metric.id}>
              <CartoucheMetric
                metric={metric.metric}
                type={metric.type}
                highlight={index === 0}
                label={metric.label}
                mobileFullWidth={index === filteredMetrics.length - 1 && index % 2 === 0}
              />
            </li>
          ))}
        </ul>
      )}
      <div className="more">
        <Button color="primary" variant="contained" href="/indicateurs">
          Voir plus
        </Button>
      </div>
    </section>
  );
};

export default HomeMetricsSection;
