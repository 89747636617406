import { RouteObject } from 'react-router-dom';

import RequireAuth from './components/RequireAuth';
import HomePage from './pages/home/HomePage';
import ArborescencePage from './pages/arborescence/ArborescencePage';
import TagPage from './pages/tag/TagPage';
import ContentPage from './pages/content-page/ContentPage';
import ContentSubpage from './pages/content-subpage/ContentSubpage';
import EventPage from './pages/event/EventPage';
import EventSearchPage from './pages/event-search/EventSearchPage';
import AccountInformationPage from './pages/account-information/AccountInformationPage';
import AccountAccessPage from './pages/account-access/AccountAccessPage';
import ContactUsPage from './pages/contact-us/ContactUsPage';
import SearchPage from './pages/search/SearchPage';
import UserRegistrationPage from './pages/user-registration/UserRegistrationPage';
import NewPasswordPage from './pages/new-password/NewPasswordPage';
import LandingPage from './pages/landing/LandingPage';
import NotFoundPage from './pages/not-found/NotFoundPage';
import ProfileParametersPage from './pages/profile-parameters/ProfileParametersPage';
import InstanceBureauxPage from './pages/instance/InstanceBureauxPage';
import InstanceCommitteesPage from './pages/instance/InstanceCommitteesPage';
import InstanceCommissionsPage from './pages/instance/InstanceCommissionsPage';
import MetricsPage from './pages/metrics/MetricsPage';
import AboutPage from './pages/about/AboutPage';
import CguPage from './pages/cgu/CguPage';
import RgpdPage from './pages/rgpd/RgpdPage';
import LegalPage from './pages/legal/LegalPage';
import ManualAccessRequestPage from './pages/manual-access-request/ManualAccessRequestPage';
import RequireNotAuth from './components/RequireNotAuth';
import DisabledAccountPage from './pages/disabled-account/DisabledAccountPage';

const routes: RouteObject[] = [
  {
    element: <RequireAuth />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/arborescence', element: <ArborescencePage /> },
      { path: '/tag/:id', element: <TagPage /> },
      { path: '/page/:id', element: <ContentPage /> },
      { path: '/sous-page/:id', element: <ContentSubpage /> },
      { path: '/evenement/:id', element: <EventPage /> },
      { path: '/evenements', element: <EventSearchPage /> },
      { path: '/organisation/informations', element: <AccountInformationPage /> },
      { path: '/organisation/acces', element: <AccountAccessPage /> },
      { path: '/contact', element: <ContactUsPage /> },
      { path: '/recherche', element: <SearchPage /> },
      { path: '/indicateurs', element: <MetricsPage /> },
      { path: '/instance/bureaux', element: <InstanceBureauxPage /> },
      { path: '/instance/commission/:id', element: <InstanceCommissionsPage /> },
      { path: '/instance/comites', element: <InstanceCommitteesPage /> },
      { path: '/profil/parametres', element: <ProfileParametersPage /> },
      { path: '/a-propos', element: <AboutPage /> },
      { path: '/cgu', element: <CguPage /> },
      { path: '/rgpd', element: <RgpdPage /> },
      { path: '/mentions-legales', element: <LegalPage /> },

      { path: '/previsualisation/tag/:id', element: <TagPage preview /> },
      { path: '/previsualisation/page/:id', element: <ContentPage preview /> },
      { path: '/previsualisation/sous-page/:id', element: <ContentSubpage preview /> },
      { path: '/previsualisation/evenement/:id', element: <EventPage preview /> },
    ],
  },
  {
    element: <RequireNotAuth />,
    children: [
      { path: '/demande-acces', element: <ManualAccessRequestPage /> },
      { path: '/nouveau-mot-de-passe/:token', element: <NewPasswordPage /> },
      { path: '/connexion', element: <LandingPage /> },
    ],
  },

  { path: '/creation-compte/:token', element: <UserRegistrationPage /> },
  { path: '/compte-desactive', element: <DisabledAccountPage /> },
  { path: '*', element: <NotFoundPage /> },
];

export default routes;
