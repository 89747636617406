import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { DownloadIcon, MenuArborescenceIcon, MenuDocumentsIcon, MenuEventsIcon } from './svg';
import {
  SearchResult,
  StrapiEvent,
  StrapiFile,
  StrapiPage,
  StrapiSubpage,
  StrapiTag,
} from '../api/strapi';
import { extractSearchResultInformation, SearchResultData } from '../utils/search';
import { Link } from 'react-router-dom';
import { computeDates } from '../utils/events';
import { UpdateSection } from './sections';
import { SearchFilters } from '../pages/search/SearchPage';
import Background from './Background';
import { useDownload } from '../hooks';
import clsx from 'clsx';

type SearchResultProps<T> = {
  item: T;
  data: SearchResultData;
  parent?: SearchResult;
};

export function SearchResultCartouche({
  result,
  filters,
}: {
  result: SearchResult;
  filters: SearchFilters;
}) {
  const data = extractSearchResultInformation(result);
  if (!data) {
    return null;
  }

  const tagsComparator = (left: StrapiTag, right: StrapiTag) => {
    const leftInFilters = filters.tags.includes(left.id);
    const rightInFilters = filters.tags.includes(right.id);

    if (leftInFilters && !rightInFilters) {
      return -1;
    } else if (!leftInFilters && rightInFilters) {
      return 1;
    } else {
      return left.title.localeCompare(right.title);
    }
  };
  data.tags.sort(tagsComparator);

  switch (result.type) {
    case 'file':
      return <SearchResultDoc item={result.data} parent={result.parent} data={data} />;
    case 'page':
      return <SearchResultPage item={result.data} data={data} />;
    case 'subpage':
      return <SearchResultSubpage item={result.data} data={data} />;
    case 'tag':
      return <SearchResultTag item={result.data} data={data} />;
    case 'event':
      return <SearchResultEvent item={result.data} data={data} />;
    default:
      return <SearchResultDefault item={undefined} data={data} />;
  }
}

function SearchResultDoc({ item, parent, data }: SearchResultProps<StrapiFile>) {
  const { name, icon } = extractSearchResultInformation(parent)!;
  const { download, ongoing } = useDownload();

  return (
    <div className="searchResultDoc">
      <div className="logo">{MenuDocumentsIcon}</div>
      <Link className="title" to={data.url}>
        {data.name}
      </Link>
      <div className="infos">
        <ul>
          <li className="type">Document</li>
          <li className="parent">
            {icon} {name}
          </li>
        </ul>
      </div>
      <UpdateSection entity={item} />
      <SearchTagSection tags={data.tags} />
      <Button className={clsx({ download: true, ongoing: ongoing })} onClick={() => download(item)}>
        {ongoing ? <CircularProgress size="2.4rem" /> : DownloadIcon}
      </Button>
    </div>
  );
}

function SearchResultEvent({ item, data }: SearchResultProps<StrapiEvent>) {
  const { start } = computeDates(item);
  return (
    <div className="searchResultEvent">
      <div className="logo">{MenuEventsIcon}</div>
      <Link className="title" to={data.url}>
        {data.name}
      </Link>
      <div className="infos">
        <ul>
          <li className="type">Évènement</li>
          <li className="date">{start?.format('DD MMMM YYYY')}</li>
          <li className="hour">{start?.format('HH[h]mm')}</li>
        </ul>
      </div>
      <SearchTagSection tags={data.tags} />
    </div>
  );
}

function SearchResultTag({ item, data }: SearchResultProps<StrapiTag>) {
  return (
    <div className="searchResultTag">
      <div className="logo">{MenuArborescenceIcon}</div>
      <Link className="title" to={data.url}>
        {data.name}
      </Link>
      <div className="infos">
        <ul>
          <li className="type">Adhésion</li>
        </ul>
      </div>
      <UpdateSection entity={item} />
      <SearchTagSection tags={data.tags} />
      {item.image && <Background className="cover" file={item.image} />}
    </div>
  );
}

function SearchResultPage({ item, data }: SearchResultProps<StrapiPage>) {
  return (
    <div className="searchResultPage">
      <div className="logo">{MenuArborescenceIcon}</div>
      <Link className="title" to={data.url}>
        {data.name}
      </Link>
      <div className="infos">
        <ul>
          <li className="type">Adhésion</li>
        </ul>
      </div>
      <UpdateSection entity={item} />
      <SearchTagSection tags={data.tags} />
    </div>
  );
}

function SearchResultSubpage({ item, data }: SearchResultProps<StrapiSubpage>) {
  return (
    <div className="searchResultSubpage">
      <div className="logo">{MenuArborescenceIcon}</div>
      <Link className="title" to={data.url}>
        {data.name}
      </Link>
      <div className="infos">
        <ul>
          <li className="type">Adhésion</li>
          <li className="parent">{item.parent.title}</li>
        </ul>
      </div>
      <UpdateSection entity={item} />
      <SearchTagSection tags={data.tags} />
    </div>
  );
}

const SearchTagSection = ({ tags }: { tags: StrapiTag[] }) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <div className="tags">
      <Link className="tag" to={`/tag/${tags[0].id}`}>
        {tags[0].title}
      </Link>
      {tags.length > 1 && <div className="tag">+{tags.length - 1}</div>}
    </div>
  );
};

function SearchResultDefault({ data }: SearchResultProps<void>) {
  return (
    <div className="searchResultInstance">
      <div className="logo">{data.icon}</div>
      <Link className="title" to={data.url}>
        {data.name}
      </Link>
      <div className="infos">
        <ul>
          <li className="type">{data.type}</li>
        </ul>
      </div>
      <SearchTagSection tags={data.tags} />
    </div>
  );
}
