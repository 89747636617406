import { Link, useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useApiQuery } from '../../hooks';
import { Copyright } from '../../components/Copyright';
import ManualAccessRequestForm from './ManualAccessRequestForm';
import { Button } from '@mui/material';
import { ENV } from '../../utils/environment';

type Step = 'form' | 'success' | 'error';

export type ManualAccessRequestFormData = {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  account: string;
  rank: {
    id: number;
    label: string;
  } | null;
  title: string;
};

const ManualAccessRequestPage = () => {
  const [searchParams] = useSearchParams();
  const { doApiQuery, ongoing } = useApiQuery();

  const [step, setStep] = useState<Step>('form');

  const submitAccessRequest = useCallback(
    async (accessRequestFormData: ManualAccessRequestFormData) => {
      if (ongoing) {
        return;
      }

      const success = await doApiQuery("votre demande d'accès", () =>
        fetch(`/api/access-request/manual`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(accessRequestFormData),
        }),
      );
      setStep(success ? 'success' : 'error');
    },
    [doApiQuery, ongoing],
  );

  return (
    <div id="accessRequestPage">
      <main>
        <Link className="logo" to="/" />
        <Copyright />
      </main>
      <aside>
        <div className="hider">
          <div className="scroll">
            <div className="content">
              <div className="inner">
                {step === 'form' && (
                  <ManualAccessRequestForm
                    initialEmail={searchParams.get('email') ?? ''}
                    onConfirm={submitAccessRequest}
                  />
                )}
                {step === 'success' && (
                  <div className="validatedStep">
                    <h1>Demande d'accès à l'espace adhérents</h1>
                    <p>
                      Merci, votre demande d'accès a été transmise au service compétent du{' '}
                      {ENV.CLIENT_NAME}. Un agent vous recontactera dans les meilleurs délais
                    </p>
                    <div className="actions">
                      <Button color="primary" variant="contained" to="/" component={Link}>
                        Retourner à l'espace adhérents
                      </Button>
                    </div>
                  </div>
                )}
                {step === 'error' && (
                  <div className="errorStep">
                    <h1>Erreur !</h1>
                    <p>
                      Une erreur est survenue lors de l'envoi de votre demande d'accès. Veuillez
                      réessayer plus tard.
                    </p>
                    <div className="actions">
                      <Button color="primary" variant="contained" to="/" component={Link}>
                        Retourner à l'espace adhérents
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};
export default ManualAccessRequestPage;
