import { Button, Link } from '@mui/material';
import { usePageFetch } from '../hooks';
import { StrapiGlobal } from '../api/strapi';

export const Tutorial = () => {
  const { data } = usePageFetch<StrapiGlobal>('/api/global');

  if (!data?.tutorialLink) {
    return null;
  }

  return (
    <Button
      className="tutorialLink"
      href={data.tutorialLink}
      component={Link}
      target="_blank"
      rel="noreferrer"
    >
      voir le tutoriel
    </Button>
  );
};
