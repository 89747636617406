export type Environment = {
  CLIENT: Client;
  CLIENT_NAME: string;
  SENTRY_ENV: string | null;
  MATOMO_ID: number | null;
};

export type Client = 'SIPPEREC' | 'SIFUREP';

const environments: Record<string, Environment> = {
  'int.sipperec-extranet.eogile.com': {
    CLIENT: 'SIPPEREC',
    CLIENT_NAME: 'SIPPEREC',
    SENTRY_ENV: 'INT',
    MATOMO_ID: null,
  },
  'test.adherent.sipperec.fr': {
    CLIENT: 'SIPPEREC',
    CLIENT_NAME: 'SIPPEREC',
    SENTRY_ENV: 'RECETTE',
    MATOMO_ID: 3,
  },
  'adherent.sipperec.fr': {
    CLIENT: 'SIPPEREC',
    CLIENT_NAME: 'SIPPEREC',
    SENTRY_ENV: 'PROD',
    MATOMO_ID: 2,
  },
  'int.sifurep-extranet.eogile.com': {
    CLIENT: 'SIFUREP',
    CLIENT_NAME: 'SIFUREP',
    SENTRY_ENV: 'SIFUREP-INT',
    MATOMO_ID: null,
  },
  'test.adherent.sifurep.com': {
    CLIENT: 'SIFUREP',
    CLIENT_NAME: 'SIFUREP',
    SENTRY_ENV: 'SIFUREP-RECETTE',
    MATOMO_ID: 7,
  },
  'adherent.sifurep.com': {
    CLIENT: 'SIFUREP',
    CLIENT_NAME: 'SIFUREP',
    SENTRY_ENV: 'SIFUREP-PROD',
    MATOMO_ID: 6,
  },
  localhost: {
    CLIENT: 'SIFUREP',
    CLIENT_NAME: 'SIFUREP',
    SENTRY_ENV: null,
    MATOMO_ID: null,
  },
};

export const ENV = environments[window.location.hostname];

export const initEnvironment = () => {
  document.title = `Espace Adhérents ${ENV.CLIENT_NAME}`;

  if (ENV.CLIENT === 'SIFUREP') {
    import('../theme/SifurepCss').then();
  } else {
    import('../theme/SipperecCss').then();
  }
};
