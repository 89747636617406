import { SearchResult, StrapiTag } from '../api/strapi';
import {
  MenuArborescenceIcon,
  MenuDocumentsIcon,
  MenuEventsIcon,
  MenuInstancesIcon,
  MenuMetricsIcon,
} from '../components/svg';
import dayjs from 'dayjs';

export type SearchResultData = {
  tags: StrapiTag[];
  name: string;
  url: string;
  icon: JSX.Element;
  type: string;
};

export const extractSearchResultInformation = (
  item?: SearchResult,
): SearchResultData | undefined => {
  if (!item || !item.type) {
    return;
  }

  switch (item.type) {
    case 'file':
      const parentData = extractSearchResultInformation(item.parent);
      if (parentData) {
        return {
          tags: parentData.tags,
          name: item.data.name,
          url: parentData.url,
          icon: MenuDocumentsIcon,
          type: 'Document',
        };
      } else {
        // Invalid search result
        return;
      }
    case 'page':
      return {
        tags: item.data.tags ?? [],
        name: item.data.title,
        url: `/page/${item.data.id}`,
        icon: MenuArborescenceIcon,
        type: 'Adhésion',
      };
    case 'subpage':
      return {
        tags: item.data.parent?.tags ?? [],
        name: item.data.title,
        url: `/sous-page/${item.data.id}`,
        icon: MenuArborescenceIcon,
        type: 'Adhésion',
      };
    case 'tag':
      return {
        tags: [item.data],
        name: item.data.title,
        url: `/tag/${item.data.id}`,
        icon: MenuArborescenceIcon,
        type: 'Adhésion',
      };
    case 'event':
      return {
        tags: item.data.tags ?? [],
        name: item.data.name,
        url: `/evenement/${item.data.id}`,
        icon: MenuEventsIcon,
        type: 'Événement',
      };
    case 'instance':
      const instanceData = extractSearchResultInformation(item.parent);
      return {
        tags: [],
        name: `${instanceData?.name} - ${dayjs(item.data.date).format('DD/MM/YYYY')}`,
        url: `${instanceData?.url}?child=${item.data.id}`,
        icon: MenuInstancesIcon,
        type: 'Instances',
      };
    case 'instance-bureau':
      return {
        tags: [],
        name: item.data.title,
        url: `/instance/bureaux`,
        icon: MenuInstancesIcon,
        type: 'Instances',
      };
    case 'instance-committee':
      return {
        tags: [],
        name: item.data.title,
        url: `/instance/comites`,
        icon: MenuInstancesIcon,
        type: 'Instances',
      };
    case 'instance-commission':
      return {
        tags: [],
        name: item.data.title,
        url: `/instance/commission/${item.data.id}`,
        icon: MenuInstancesIcon,
        type: 'Instances',
      };
    case 'metric':
      return {
        tags: item.data.tags ?? [],
        name: item.data.label,
        url: `/indicateurs?metricId=${item.data.id}`,
        icon: MenuMetricsIcon,
        type: 'Indicateur',
      };
    default:
      return;
  }
};
