import { PasswordStep } from '../../components/PasswordChange';

type PasswordFormProps = {
  onConfirm: (password: string) => void;
};

const PasswordForm = ({ onConfirm }: PasswordFormProps) => {
  return (
    <PasswordStep title={'Création de mot de passe'} onPasswordSet={onConfirm}>
      Afin de pouvoir accéder à l'espace adhérent, merci de définir un mot de passe.
    </PasswordStep>
  );
};

export default PasswordForm;
