import React, { ReactNode } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { CancelIcon, DownloadIcon, EmailIcon, PhoneIcon, ValidateIcon } from './svg';
import classNames from 'classnames';
import {
  StrapiEvent,
  StrapiFile,
  StrapiMetric,
  StrapiMetricsType,
  StrapiNewsletter,
  StrapiReferral,
  StrapiSubpage,
} from '../api/strapi';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { computeDates } from '../utils/events';
import { prependHttp } from '../utils/utils';
import { useDownload, useMobileOrTablet } from '../hooks';
import clsx from 'clsx';

export function CartoucheDoc({ file, name }: { file: StrapiFile; name?: string }) {
  const { download, ongoing } = useDownload();

  return (
    <div className="cartoucheDoc">
      <div className="logo" onClick={() => download(file)} />
      <div className="title" onClick={() => download(file)}>
        {name ?? file.name}
      </div>
      <div className="infos">{new Date(file.updatedAt).toLocaleString('fr')}</div>
      <Button
        color="secondary"
        className={clsx({ download: true, ongoing: ongoing })}
        onClick={() => download(file)}
      >
        {ongoing ? <CircularProgress size="2.4rem" /> : DownloadIcon}
      </Button>
    </div>
  );
}

export function CartoucheEvent({ event }: { event: StrapiEvent }) {
  const attendance = event.publication.attendances[0];
  const status = attendance?.status;

  const { start, past } = computeDates(event);

  return (
    <div className={classNames('cartoucheEvent', past ? 'past' : 'future')}>
      <Link className="logo" to={`/evenement/${event.id}`} />
      <Link className="title" to={`/evenement/${event.id}`}>
        {event.name}
      </Link>
      <div className="infos">
        {start?.format('DD MMM YYYY')}
        {past && <em>(évènement passé)</em>}
      </div>
      <div className="actions">
        <Link className={status} to={`/evenement/${event.id}`}>
          {status === 'dontParticipate' && <>{CancelIcon}Je ne participe pas</>}
          {(status === 'participate' || status === 'participateRemote') && (
            <>{ValidateIcon}Je participe</>
          )}
          {status !== 'dontParticipate' &&
            status !== 'participate' &&
            status !== 'participateRemote' && <>{ValidateIcon}M'inscrire</>}
        </Link>
      </div>
    </div>
  );
}

export function CartoucheMarket({ subpage }: { subpage: StrapiSubpage }) {
  return (
    <div className="cartoucheMarket">
      <Link className="logo" to={`/sous-page/${subpage.id}/`} />
      <Link className="title" to={`/sous-page/${subpage.id}/`}>
        {subpage.title}
      </Link>
    </div>
  );
}

export function CartoucheNewsletter({ newsletter }: { newsletter: StrapiNewsletter }) {
  const date = dayjs(newsletter.date);

  return (
    <div className="cartoucheNewsletter">
      <a className="title" target="_blank" rel="noreferrer" href={prependHttp(newsletter.link)}>
        {newsletter.title}
      </a>
      <div className="date">
        <Box sx={{ display: 'flex', gap: (theme) => '2px' }}>
          <span className="day">{date.format('DD')}</span>
          <span className="month">{date.format('MMM')}</span>
        </Box>
        <span className="year">{date.format('YYYY')}</span>
      </div>
    </div>
  );
}

export function CartoucheReferral({ referral }: { referral: StrapiReferral }) {
  return (
    <div className="cartoucheUser">
      <div className="logo" />
      <div className="title">
        {referral.firstname} {referral.lastname}
      </div>
      <div className="infos">{referral.account}</div>
      <div className="infos">{referral.title}</div>
      <div className="actions">
        {referral.email && (
          <a href={`mailto:${referral.email}`}>
            {EmailIcon}
            Envoyer un e-mail
          </a>
        )}
        {referral.phone && (
          <a className="phone" href={`tel:${referral.phone.replace(/\s/g, '')}`}>
            {PhoneIcon}
            {referral.phone}
          </a>
        )}
      </div>
    </div>
  );
}

type CartoucheMetricsProps = {
  highlight?: boolean;
  metric: StrapiMetric;
  label: string;
  type: StrapiMetricsType;
  mobileFullWidth: boolean;
};

export function CartoucheMetric({
  highlight,
  metric,
  label,
  type,
  mobileFullWidth,
}: CartoucheMetricsProps) {
  const numericValue = (metric?.accountMetrics ?? [])[0]?.value;
  const stringValue = (metric?.accountMetrics ?? [])[0]?.valueStr;
  const mobile = useMobileOrTablet();

  let value: string;
  switch (metric.type) {
    case 'boolean':
      value = numericValue ? 'Oui' : 'Non';
      break;
    case 'scalar':
      if (numericValue === null || numericValue === undefined) {
        value = '-';
      } else {
        const format =
          numericValue < 1000
            ? {}
            : {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              };
        value = new Intl.NumberFormat('fr-FR', format).format(numericValue);
      }
      break;
    case 'text':
      value = stringValue ?? '-';
      break;
    case 'date':
      const date = dayjs(stringValue);
      value = date.isValid() ? date.format('DD/MM/YYYY') : '-';
      break;
  }

  let sizeClass;
  if (!mobile || mobileFullWidth) {
    sizeClass = '';
  } else if (value.length >= 8) {
    sizeClass = 'smaller-value';
  } else if (value.length >= 4) {
    sizeClass = 'small-value';
  } else {
    sizeClass = '';
  }

  const subpageId = metric.subpage?.id;
  const link = subpageId ? `/sous-page/${subpageId}` : undefined;

  return (
    <OptionalLink link={link}>
      <div className={classNames('metric', type, highlight && 'highlight')}>
        <div className="inner">
          <div className="value">
            <strong className={sizeClass}>{value}</strong>
            {metric.unit && value !== '-' && <span className="unit">{metric.unit}</span>}
          </div>
          <div className="label">{label}</div>
        </div>
      </div>
    </OptionalLink>
  );
}

/** Sometimes there's a link, sometimes there's not. */
const OptionalLink = ({ link, children }: { link?: string; children: ReactNode }) => {
  if (link) {
    return <Link to={link}>{children}</Link>;
  } else {
    return <>{children}</>;
  }
};
