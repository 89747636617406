import { Link } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { Button } from '@mui/material';
import { Copyright } from './Copyright';
import PasswordField from './PasswordField';

export type PasswordChangeStep = 'loading' | 'password' | 'validated' | 'error';

type PasswordChangeProps = {
  step: PasswordChangeStep;
  onConfirm: (password: string) => void;
  title: ReactNode;
  passwordContent: ReactNode;
  validatedContent: ReactNode;
};

const PasswordChange = ({
  step,
  onConfirm,
  title,
  passwordContent,
  validatedContent,
}: PasswordChangeProps) => {
  return (
    <>
      {step !== 'loading' && (
        <>
          <div id="confirmationPage">
            <main>
              <Link className="logo" to="/" />
              <Copyright />
            </main>
            <aside>
              <div className="hider">
                <div className="scroll">
                  <div className="content">
                    <div className="inner">
                      {step === 'password' && (
                        <PasswordStep title={title} onPasswordSet={onConfirm}>
                          {passwordContent}
                        </PasswordStep>
                      )}
                      {step === 'validated' && <ValidatedStep>{validatedContent}</ValidatedStep>}
                      {step === 'error' && <ErrorStep />}
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </>
      )}
    </>
  );
};

type PasswordStepProps = {
  onPasswordSet: (password: string) => void;
  title: ReactNode;
  children: ReactNode;
};

export const PasswordStep = ({ onPasswordSet, title, children }: PasswordStepProps) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [rulesError, setRulesError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);

  return (
    <div className="formStep">
      <h1>{title}</h1>
      <div className="explanation">
        <p>{children}</p>
        <p>
          <strong>
            Celui-ci doit comprendre au moins 12 caractères, et doit contenir les types de caractère
            suivants :
          </strong>
        </p>
        <ul>
          <li>minuscules</li>
          <li>majuscules</li>
          <li>chiffres</li>
          <li>caractères spéciaux (#,@,?,$...)</li>
        </ul>
      </div>
      <PasswordField
        label="Mot de passe"
        onChange={(e) => setPassword(e.target.value)}
        autoComplete="new-password"
      />
      <PasswordField
        label="Confirmation du mot de passe"
        onChange={(e) => setConfirmPassword(e.target.value)}
        autoComplete="new-password"
      />

      {(rulesError || confirmError) && (
        <div className="error">
          {rulesError && (
            <div>Le mot de passe renseigné ne correspond pas aux règles de création.</div>
          )}
          {confirmError && <div>Les mots de passe ne correspondent pas.</div>}
        </div>
      )}
      <div className="actions">
        <Button
          color="primary"
          disabled={!password}
          onClick={() => {
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{12,}$/;
            const rulesTest = regex.test(password);
            const confirmTest = password === confirmPassword;
            setRulesError(!rulesTest);
            setConfirmError(!confirmTest);
            if (rulesTest && confirmTest) {
              onPasswordSet(password);
            }
          }}
        >
          Valider
        </Button>
      </div>
    </div>
  );
};

type ValidatedStepProps = { children: ReactNode };

const ValidatedStep = ({ children }: ValidatedStepProps) => {
  return (
    <div className="validatedStep">
      <h1>C'est enregistré !</h1>
      {children}
      <div className="actions">
        <Link className="button primary" to="/">
          Retourner à l'espace adhérents
        </Link>
      </div>
    </div>
  );
};

const ErrorStep = () => {
  return (
    <div className="errorStep">
      <h1>Erreur !</h1>
      <p>Ce lien de confirmation a expiré.</p>
      <div className="actions">
        <Link className="button primary" to="/">
          Retourner à l'espace adhérents
        </Link>
      </div>
    </div>
  );
};

export default PasswordChange;
