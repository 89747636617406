import React, { LegacyRef, useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import { Link, useNavigate } from 'react-router-dom';

import { SearchIcon } from '../svg';
import { HeaderSearchResultCartouche } from './searchResults';
import { useApiQuery, useDebounce } from '../../hooks';
import TextField from '../TextField';
import { useUser } from '../../providers/UserProvider';
import { SearchResult, SearchResults } from '../../api/strapi';
import { extractSearchResultInformation } from '../../utils/search';
import { Autocomplete } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';

export const Search = () => {
  const { fetch } = useUser();
  const navigate = useNavigate();
  const [results, setResults] = useState<SearchResults>();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 400);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const { doApiQuery } = useApiQuery();

  const validateSearch = useCallback(() => {
    navigate(`/recherche?query=${query}`);
    setAutocompleteOpen(false);
  }, [query, navigate]);

  useEffect(() => {
    const effect = async () => {
      if (debouncedQuery.length < 3) {
        setResults(undefined);
        return;
      }

      const queryParams = qs.stringify(
        {
          query: debouncedQuery,
        },
        { encodeValuesOnly: true },
      );

      await doApiQuery("l'autocomplétion", async () => {
        const response = await fetch(`/api/autocomplete?${queryParams}`);
        if (response.ok) {
          const results: { data: SearchResults } = await response.json();
          setResults(results.data);
        }
        return response;
      });
    };
    effect().then();
  }, [debouncedQuery, doApiQuery, fetch]);

  return (
    <div className="search">
      <Autocomplete
        classes={{ popper: 'headerSearchPopper' }}
        onChange={(e, val) => {
          const data = extractSearchResultInformation(val as SearchResult);
          if (data) {
            navigate(data.url);
          }
        }}
        open={Boolean(results?.results.length) && autocompleteOpen}
        onOpen={() => setAutocompleteOpen(true)}
        onClose={() => setAutocompleteOpen(false)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            validateSearch();
          }
        }}
        onInputChange={(e, val) => {
          if (e?.type === 'change') {
            setQuery(val);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Saisissez votre recherche"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div onClick={validateSearch} className="headerSearchIcon">
                  {SearchIcon}
                </div>
              ),
            }}
          />
        )}
        inputValue={query}
        getOptionLabel={() => ''}
        options={results?.results ?? []}
        filterOptions={(x) => x}
        ListboxComponent={React.forwardRef((props, ref: LegacyRef<HTMLUListElement>) => (
          <ul ref={ref} {...props}>
            {props.children}
            <li className="searchButton">
              <Link to={`/recherche?query=${query}`}>Rechercher</Link>
            </li>
          </ul>
        ))}
        renderOption={(props, option: SearchResult) => (
          <ErrorBoundary key={option.searchId}>
            <li {...props}>
              <HeaderSearchResultCartouche result={option} />
            </li>
          </ErrorBoundary>
        )}
      />
    </div>
  );
};
