import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import InstancePage, { Instance } from './InstancePage';
import { StrapiInstanceCommission } from '../../api/strapi';

const transformResponse = (data: StrapiInstanceCommission): Instance => ({
  title: data.title,
  menu: data.menu,
  content: data.content,
  referrals: data.referrals,
  items: data.commissions.map((itemData) => ({
    id: itemData.id,
    date: dayjs(itemData.date),
    event: itemData.event,
    attachments: [
      {
        type: 'Ordre du jour',
        file: itemData.agenda,
      },
      {
        type: 'Compte-rendu',
        file: itemData.report,
      },
      {
        type: 'Présentation',
        file: itemData.presentation,
      },
    ],
  })),
});

const InstanceCommissionsPage = () => {
  const { id } = useParams();
  return (
    <InstancePage<StrapiInstanceCommission>
      itemTitle="Commission"
      query={`/api/instance-commissions/${id}`}
      transformResponse={transformResponse}
    />
  );
};

export default InstanceCommissionsPage;
