import { StrapiEvent, StrapiFile } from '../api/strapi';
import dayjs from 'dayjs';

/** Returns the cover of the event, then the cover of its first tag. */
export const cover = (event: StrapiEvent): StrapiFile | undefined => {
  if (event.cover) {
    return event.cover;
  }

  return event.tags
    .map((tag) => tag.image)
    .filter((image) => Boolean(image))
    .map((image) => image!)[0];
};

export const computeDates = (event: StrapiEvent) => {
  const now = dayjs();
  const start = event.publication.start;
  const end = event.publication.end;

  const startDate = start ? dayjs(start) : undefined;
  const endDate = end ? dayjs(end) : undefined;

  const daysDuration =
    startDate && endDate && endDate.startOf('day').diff(startDate.startOf('day'), 'day') + 1;

  // Defines if the event is in the past or future
  const past = startDate && startDate.startOf('day') < now.startOf('day');

  return {
    start: startDate,
    end: endDate,
    duration: daysDuration,
    past,
  };
};
