import { Alert, Button } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../providers/UserProvider';
import { FromLocationState } from '../../components/RequireAuth';
import ForgottenPassword from './ForgottenPassword';
import PasswordField from '../../components/PasswordField';
import EmailTextField from '../../components/EmailTextField';

interface LoginFormElements extends HTMLFormControlsCollection {
  identifier: HTMLInputElement;
  password: HTMLInputElement;
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: LoginFormElements;
}

const LoginForm = () => {
  const { login } = useUser();
  const navigate = useNavigate();

  const location = useLocation();
  const from = (location.state as FromLocationState)?.from?.pathname || '/';

  const [error, setError] = useState<string>();
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');

  const handleIdentifierChange = (event: ChangeEvent<HTMLInputElement>) =>
    setIdentifier(event.target.value);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const handleLogin = async (event: FormEvent<LoginFormElement>) => {
    event.preventDefault();
    try {
      await login(identifier, password);
      setError(undefined);
      navigate(from, { replace: true });
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <form noValidate onSubmit={handleLogin}>
      <h2>Connectez-vous</h2>
      {error && <Alert severity="error">{error}</Alert>}
      <EmailTextField
        className="form-item"
        id="identifier"
        label="Adresse e-mail"
        required={true}
        value={identifier}
        onChange={handleIdentifierChange}
        placeholder="Saisissez votre adresse e-mail"
      />
      <PasswordField
        id="password"
        label="Mot de passe"
        required={true}
        value={password}
        onChange={handlePasswordChange}
        placeholder="Saisissez votre mot de passe"
      />
      <ForgottenPassword defaultEmail={identifier} />
      <div className="save">
        <Button type="submit" color="primary" disabled={!identifier || !password}>
          Me connecter
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
