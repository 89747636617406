import { Button, FormControl, MenuItem, Select, TextareaAutosize } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StrapiRequestCategory } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import Error from '../../components/Error';
import { useApiQuery, usePageFetch } from '../../hooks';
import TextField from '../../components/TextField';
import { PhoneIcon, WebsiteIcon } from '../../components/svg';
import { useUser } from '../../providers/UserProvider';
import DropzoneArea from '../../components/dropzone/DropzoneArea';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import BackButton from '../../components/BackButton';
import { ENV } from '../../utils/environment';

type Step = 'form' | 'success' | 'error';

const ACCEPTED_FILES = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.rtf',
  '.jpg',
  '.jpeg',
  '.png',
  '.odt',
];

const ContactUsPage = () => {
  const { fetch, account } = useUser();
  const [params] = useSearchParams();
  const [categoryId, setCategoryId] = useState<number>();
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const dropzone = useRef<any>(null);
  const [step, setStep] = useState<Step>('form');
  const { doApiQuery, ongoing } = useApiQuery();
  const { enqueueSnackbar } = useSnackbar();

  const { data, error } = usePageFetch<StrapiRequestCategory[]>(`/api/request-categories`);

  useEffect(() => {
    const categoryStr = params.get('category');
    if (categoryStr) {
      setCategoryId(parseInt(categoryStr));
    } else {
      setCategoryId(undefined);
    }
  }, [params]);

  const canSend = categoryId !== undefined && subject.length > 0 && description.length > 0;
  const sendRequest = useCallback(async () => {
    setShowErrors(true);
    if (ongoing) {
      return;
    }
    if (!canSend) {
      enqueueSnackbar(
        "Veuillez renseigner la catégorie, objet et description de votre demande avant de l'envoyer.",
        { variant: 'warning' },
      );
      return;
    }
    const formData = new FormData();
    formData.append('categoryId', categoryId!.toString());
    formData.append('subject', subject);
    formData.append('description', description);

    dropzone.current?.state.fileObjects.forEach((fo: any, i: number) =>
      formData.append(`file_${i}`, fo.file),
    );

    const success = await doApiQuery("l'envoi de la demande", async () => {
      return await fetch('/api/request', {
        method: 'POST',
        body: formData,
      });
    });
    if (success) {
      setStep('success');
    } else {
      setStep('error');
    }
  }, [ongoing, categoryId, subject, description, doApiQuery, fetch, canSend, enqueueSnackbar]);

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      {data && (
        <div id="contactUsPage">
          <div id="top">
            <BackButton />
            <div className="content">
              <h1>Nous contacter</h1>
            </div>
          </div>
          <div id="bottom">
            <main>
              {step === 'form' && (
                <FormControl>
                  <div className="form-item">
                    <label>Organisation</label>
                    <TextField value={account?.name} disabled />
                  </div>
                  <div className="form-item mandatory">
                    <label>Catégorie</label>
                    <Select
                      labelId="request-type-label"
                      id="request-type-select"
                      value={categoryId ?? ''}
                      label="Catégorie"
                      onChange={(e) => setCategoryId(e.target.value as number)}
                      error={showErrors && categoryId === undefined}
                    >
                      {data.map((category) => (
                        <MenuItem value={category.id} key={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="form-item mandatory">
                    <label>Objet</label>
                    <TextField
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      error={showErrors && subject.length === 0}
                    />
                  </div>
                  <div className="form-item mandatory">
                    <label>Description</label>
                    <TextareaAutosize
                      className={clsx({ error: showErrors && description.length === 0 })}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="form-item dropzone-field">
                    <label>Pièces jointes</label>
                    <DropzoneArea
                      ref={dropzone}
                      initialFiles={[]}
                      filesLimit={10}
                      clearOnUnmount={true}
                      fileObjects={[]}
                      acceptedFiles={ACCEPTED_FILES}
                      getFileLimitExceedMessage={() => {
                        return 'Vous ne pouvez pas joindre plus de 10 fichiers.';
                      }}
                      getDropRejectMessage={(rejection) => {
                        switch (rejection.errors[0].code) {
                          case 'file-too-large':
                            return 'La taille des pièce-jointes ne peut pas dépasser 3Mo';
                          case 'file-invalid-type':
                            return `Pour des raisons de sécurité, seuls les types de fichiers suivants sont autorisés : ${ACCEPTED_FILES.join(
                              ', ',
                            )}`;
                          default:
                            return 'Ce fichier ne peut pas nous être transmis';
                        }
                      }}
                      onAlert={(msg, type) => {
                        if (type === 'error') {
                          enqueueSnackbar(msg, { variant: type });
                        }
                      }}
                      showAlerts={false}
                      showFileNames={true}
                      dropzoneText="Déposez vos pièces-jointes ici ou cliquez pour ouvrir un sélecteur de fichier."
                      dropzoneClass="dropzone"
                      dropzoneParagraphClass="intro"
                    />
                  </div>
                  <div className="send">
                    <Button onClick={sendRequest}>
                      {ongoing ? "En cours d'envoi..." : 'Envoyer'}
                    </Button>
                  </div>
                </FormControl>
              )}
              {step === 'success' && (
                <div className="infoSuccess">
                  <h2>Merci !</h2>
                  <p>
                    Votre demande a été transmise, nous vous recontacterons dans les meilleurs
                    délais.
                  </p>
                  <Button href="/">Retourner à l'accueil</Button>
                </div>
              )}
              {step === 'error' && (
                <div className="infoError">
                  <h2>Erreur</h2>
                  <p>
                    Un incident est survenu lors de l'envoi de votre demande, merci de ré-essayer
                    ultérieurement.
                  </p>
                  <Button href="/">Retourner à l'accueil</Button>
                </div>
              )}
            </main>
            <aside>
              <section className="address">
                <h2>Adresse</h2>
                <strong>{ENV.CLIENT_NAME}</strong>
                <div className="address">
                  Tour Lyon Bercy
                  <br />
                  173-175 rue de Bercy
                  <br />
                  CS 10205
                  <br />
                  75588 PARIS CEDEX 12
                </div>
                {ENV.CLIENT === 'SIPPEREC' ? (
                  <>
                    <a className="phone" href="tel:+33144743200">
                      {PhoneIcon}01 44 74 32 00
                    </a>
                    <a href="https://www.sipperec.fr" target="_blank" rel="noreferrer">
                      {WebsiteIcon}www.sipperec.fr
                    </a>
                  </>
                ) : (
                  <>
                    <a className="phone" href="tel:+33144748570">
                      {PhoneIcon}01 44 74 85 70
                    </a>
                    <a href="https://www.sifurep.com" target="_blank" rel="noreferrer">
                      {WebsiteIcon}www.sifurep.com
                    </a>
                  </>
                )}
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10502.816103108766!2d2.372512!3d48.844784000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6721a566ecb05%3A0xeed5453e5e74c204!2s175+Rue+de+Bercy%2C+75012+Paris!5e0!3m2!1sen!2sfr!4v1514390822241"
                    width="100%"
                    height="320"
                    frameBorder="0"
                    style={{ border: 0 }}
                    aria-hidden="false"
                    title="175 Rue de Bercy"
                  />
                </div>
              </section>
            </aside>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ContactUsPage;
