import React from 'react';
import { StrapiSubpage } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { useParams } from 'react-router-dom';
import {
  DocumentsSection,
  EventsSection,
  NewslettersSection,
  PreviewSection,
  ReferralsSection,
  RichTextSection,
  TagsSection,
  UpdateSection,
} from '../../components/sections';
import { PreviewPageProps } from '../page-types';
import Error from '../../components/Error';
import { useDesktop, usePageFetch } from '../../hooks';
import BackButton from '../../components/BackButton';

const ContentSubpage = ({ preview }: PreviewPageProps) => {
  const { id } = useParams();
  const desktop = useDesktop();

  const { data, error } = usePageFetch<StrapiSubpage>(
    `/api/subpages/${id}${preview ? '?preview=true' : ''}`,
  );

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      {preview && <PreviewSection />}
      <Header />
      {data && (
        <div id="contentSubPage">
          <div id="bottom">
            <main>
              <div id="top">
                <BackButton />
                <div className="content">
                  <h1>
                    {data.title}
                    {preview && <span className="preview"> (prévisualisation)</span>}
                  </h1>
                  <TagsSection tags={data.parent.tags} />
                  <UpdateSection entity={data} />
                </div>
              </div>
              {!desktop && (
                <ReferralsSection referrals={data.referrals} title="Les interlocuteurs" />
              )}
              <RichTextSection html={data.content} />
              <DocumentsSection documents={data.documents} title="Les documents liés" />
            </main>
            <aside>
              {desktop && (
                <ReferralsSection referrals={data.referrals} title="Les interlocuteurs" />
              )}
              <EventsSection events={data.events} />
              <NewslettersSection newsletters={data.newsletters} />
            </aside>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ContentSubpage;
