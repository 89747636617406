import { Dayjs } from 'dayjs';

type DatesSectionProps = {
  start?: Dayjs;
  end?: Dayjs;
};

const DatesSection = ({ start, end }: DatesSectionProps) => {
  // Case 1 : no start date
  if (!start) {
    return null;
  }

  // Case 2 : no end date
  if (!end) {
    return (
      <>
        <li className="date">{start.format('DD MMMM YYYY')}</li>
        <li className="hour">{start.format('HH[h]mm')}</li>
      </>
    );
  }

  const dayDiff = end.startOf('day').diff(start.startOf('day'), 'day');

  // Case 3 : multi-day
  if (dayDiff > 0) {
    return (
      <li className="date">
        {start.format('[du] DD MMMM YYYY')}
        {start.format(' à HH[h]mm')}
        {end.format(' [au] DD MMMM YYYY')}
        {end.format(' à HH[h]mm')}
      </li>
    );
  } else {
    // Case 4 : same-day
    return (
      <>
        <li className="date">{start.format('DD MMMM YYYY')}</li>
        <li className="hour">{`de ${start.format('HH[h]mm')} à ${end.format('HH[h]mm')}`}</li>
      </>
    );
  }
};

export default DatesSection;
