import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { validateEmail } from '../../utils/validation';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CloseIcon } from '../../components/svg';
import { useApiQuery } from '../../hooks';
import { StrapiLandingPage } from '../../api/strapi';
import EmailTextField from '../../components/EmailTextField';
import { ENV } from '../../utils/environment';

type DialogStep = 'fill' | 'sent' | 'error';

type AccessRequestProps = {
  data: StrapiLandingPage;
  openOnLoad: boolean;
};

const AccessRequest = ({ data, openOnLoad }: AccessRequestProps) => {
  const [dialogStep, setDialogStep] = useState<DialogStep>('fill');
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [showErrors, setShowErrors] = useState(false);
  const { doApiQuery, ongoing } = useApiQuery();

  useEffect(() => {
    if (openOnLoad) {
      setOpen(true);
    }
  }, [openOnLoad]);

  let validationError: string | null = null;
  if (email.trim().length === 0) {
    validationError = `Veuillez renseigner un e-mail.`;
  } else if (!validateEmail(email)) {
    validationError = "L'e-mail renseigné n'est pas valide.";
  }

  const openDialog = () => {
    setDialogStep('fill');
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const requestAccess = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (ongoing) {
        return;
      }
      if (validationError) {
        setShowErrors(true);
        return;
      }

      const success = doApiQuery("l'envoi de la demande", async () => {
        const response = await fetch(`/api/access-request`, {
          method: 'POST',
          body: JSON.stringify({ email: email.toLowerCase() }),
        });

        setDialogStep(response.ok ? 'sent' : 'error');
        return response;
      });
      if (!success) {
        setDialogStep('error');
      }
    },
    [doApiQuery, email, ongoing, validationError],
  );

  return (
    <>
      <section className="request">
        <h2>Vous n'avez pas de compte ?</h2>
        <div className="save">
          <Button color="secondary" onClick={openDialog}>
            Faites une demande
          </Button>
        </div>
      </section>
      <Dialog className="inviteDialog" open={open} onClose={closeDialog}>
        {dialogStep === 'fill' && (
          <form noValidate onSubmit={requestAccess}>
            <DialogTitle>Demande d'accès à l'espace adhérents</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <i>
                  {data.accessText?.length > 0
                    ? data.accessText
                    : `Renseignez votre email ici pour demander l'accès à l'espace adhérents du ${ENV.CLIENT_NAME}. ` +
                      'Vous recevrez un email dans les plus brefs délais pour créer votre compte.'}
                </i>
              </DialogContentText>
              <EmailTextField
                autoFocus
                margin="dense"
                required
                label="Adresse e-mail"
                fullWidth
                variant="standard"
                value={email}
                error={showErrors && Boolean(validationError)}
                helperText={showErrors && validationError}
                onChange={(e) => setEmail(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={closeDialog}>
                Annuler
              </Button>
              <Button disabled={ongoing} type="submit">
                {ongoing ? 'Demande en cours...' : 'Demander un accès'}
              </Button>
            </DialogActions>
          </form>
        )}
        {dialogStep === 'sent' && (
          <div className="infoSuccess">
            <Button className="close" onClick={closeDialog}>
              {CloseIcon}
            </Button>
            <DialogTitle>Demande d'accès envoyée&nbsp;!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Votre demande d'accès a été envoyée au {ENV.CLIENT_NAME}, vous recevrez un email de
                confirmation sous peu.
                <p>
                  <strong>Pensez à consulter vos SPAMs.</strong>
                </p>
              </DialogContentText>
            </DialogContent>
          </div>
        )}
        {dialogStep === 'error' && (
          <div className="infoError">
            <Button className="close" onClick={closeDialog}>
              {CloseIcon}
            </Button>
            <DialogTitle>Erreur lors de la demande d'accès</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Une erreur est survenue lors du traitement de votre demande. Veuillez rééssayer
                ultérieurement.
              </DialogContentText>
            </DialogContent>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AccessRequest;
