import { StrapiFile } from '../api/strapi';
import { useUser } from '../providers/UserProvider';
import { ReactNode, useCallback, useEffect, useState } from 'react';

type WithBackgroundProps = {
  file?: StrapiFile;
  children?: ReactNode;
  defaultUrl?: string;
  className?: string;
};

const Background = ({ file, children, defaultUrl, className }: WithBackgroundProps) => {
  const { fetch } = useUser();
  const [background, setBackground] = useState(defaultUrl);

  const fetchImage = useCallback(async () => {
    try {
      if (file) {
        const response = await fetch(`/api/media/${file.hash}${file.ext}`);
        const blob = await response.blob();
        setBackground(URL.createObjectURL(blob));
        return;
      }
    } catch {}
    setBackground(defaultUrl);
  }, [fetch, file, defaultUrl]);

  useEffect(() => {
    fetchImage().then();
  }, [fetchImage]);

  const style = background ? { backgroundImage: `url(${background})` } : undefined;
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Background;
