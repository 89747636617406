import {
  StrapiEntity,
  StrapiEvent,
  StrapiFile,
  StrapiNewsletter,
  StrapiPage,
  StrapiReferral,
  StrapiTag,
} from '../api/strapi';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CartoucheDoc, CartoucheEvent, CartoucheNewsletter, CartoucheReferral } from './cartouches';
import clsx from 'clsx';
import { ExpandMoreIcon } from './svg';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDesktop } from '../hooks';

export function DocumentsSection({ documents, title }: { documents: StrapiFile[]; title: string }) {
  if (!documents || documents.length === 0) {
    return null;
  }
  return (
    <section className="files">
      <h2>{title}</h2>
      <ul>
        {documents.map((document) => (
          <li key={document.id}>
            <CartoucheDoc file={document} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export function TagsSection({ tags }: { tags: StrapiTag[] }) {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <div className="tags">
      <ul>
        {tags.map((tag) => (
          <li key={tag.id}>
            <Link
              to={`/tag/${tag.id}`}
              className={clsx({
                tag: true,
                primary: tag.type === 'thematic',
                secondary: tag.type !== 'thematic',
              })}
            >
              {tag.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function ReferralsSection({
  referrals,
  title,
}: {
  referrals: StrapiReferral[];
  title: string;
}) {
  if (!referrals || referrals.length === 0) {
    return null;
  }

  // Order by : account then last name then first name
  const accountComparator = (a: string, b: string) => {
    if (a === b) return 0;
    if (a === 'SIPPEREC') return 1;
    if (b === 'SIPPEREC') return -1;
    return a.localeCompare(b);
  };

  referrals.sort((a, b) => {
    return (
      accountComparator(a.account, b.account) ||
      a.lastname.localeCompare(b.lastname) ||
      a.firstname.localeCompare(b.firstname)
    );
  });

  return (
    <section className="users">
      <h2>{title}</h2>
      <ul className="list">
        {referrals.map((referral) => (
          <li className="item" key={referral.id}>
            <CartoucheReferral referral={referral} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export function EventsSection({ events }: { events?: StrapiEvent[] }) {
  const [open, setOpen] = useState(true);

  const desktop = useDesktop();
  useEffect(() => {
    setOpen(desktop);
  }, [desktop]);

  if (!events || events.length === 0) {
    return null;
  }

  return (
    <section
      className={clsx({
        events: true,
        togglableSection: true,
        open: open,
      })}
    >
      <Button
        className="toggle"
        onClick={(e) => {
          setOpen(!open);
          e.preventDefault();
        }}
      >
        {ExpandMoreIcon}
      </Button>
      <h2>Évènements</h2>
      <div className="count">{events.length}</div>
      <ul className="list">
        {events.map((event) => (
          <li className="item">
            <CartoucheEvent event={event} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export function NewslettersSection({ newsletters }: { newsletters: StrapiNewsletter[] }) {
  if (!newsletters || newsletters.length === 0) {
    return null;
  }
  return (
    <section className="newsletters">
      <h2>Les dernières communications</h2>
      <ul className="list">
        {newsletters.map((newsletter) => (
          <li className="item" key={newsletter.id}>
            <CartoucheNewsletter newsletter={newsletter} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export function PagesSection({ pages }: { pages: StrapiPage[] }) {
  if (!pages || pages.length === 0) {
    return null;
  }
  return (
    <section className="pages">
      <h2>Arborescence des offres</h2>
      <div className="pages">
        <ul>
          {pages.map((page) => (
            <li key={page.id}>
              <Link to={`/page/${page.id}`}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export function UpdateSection({ entity }: { entity?: StrapiEntity<any> }) {
  if (!entity) {
    return null;
  }
  return <div className="update">Mis à jour le {dayjs(entity.updatedAt).format('DD/MM/YYYY')}</div>;
}

export function PreviewSection() {
  return <div id="previewBanner">Mode Prévisualisation</div>;
}

export function RichTextSection({ html }: { html: string }) {
  return <section className="richtext" dangerouslySetInnerHTML={{ __html: html }} />;
}
