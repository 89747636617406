import * as Sentry from '@sentry/react';
import { ENV } from '../utils/environment';

export const init = () => {
  const sentryEnv = ENV.SENTRY_ENV;
  if (sentryEnv !== null) {
    Sentry.init({
      dsn: 'https://c735801f2f8d44e89e50ad526b9f989f@o50061.ingest.sentry.io/6215511',
      environment: sentryEnv,
    });
  }
};
