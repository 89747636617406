import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { CartoucheEvent, CartoucheMarket } from '../../components/cartouches';
import { StrapiHomePage } from '../../api/strapi';
import { useUser } from '../../providers/UserProvider';
import { formatUser } from '../../api/format';
import { usePageFetch } from '../../hooks';
import HomeMetricsSection from './HomeMetricsSection';
import Background from '../../components/Background';
import HomeNewslettersSection from './HomeNewslettersSection';
import { useMemo } from 'react';

const HomePage = () => {
  const { user, account } = useUser();

  const { data } = usePageFetch<StrapiHomePage>('/api/home');
  const homeData = data?.home;
  const homeTag = homeData?.tag;
  const newsletters = useMemo(() => data?.newsletters ?? [], [data]);

  return (
    <>
      <Header />
      <div id="homePage">
        <div className="intro">
          <div className="welcome">
            <span>Bienvenue</span> {formatUser(user)}
          </div>
          <div className="infos">
            <span>{account?.jobTitle ?? account?.jobRank?.label}</span>
            <span>{account?.name}</span>
          </div>
        </div>
        <div className="content">
          <HomeMetricsSection metrics={data?.home.metrics} />
          <section className="markets">
            <h2>Mes nouveaux marchés</h2>
            <ul className="list">
              {data?.subpages.map((subpage) => (
                <li className="item" key={subpage.id}>
                  <CartoucheMarket subpage={subpage} />
                </li>
              ))}
            </ul>
            {homeTag && (
              <div className="more">
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to={`/tag/${homeTag.id}`}
                >
                  Voir plus
                </Button>
              </div>
            )}
          </section>
          <section className="events">
            <h2>Mes prochains évènements</h2>
            <ul className="list">
              {data?.events?.map((event) => (
                <li className="item" key={event.id}>
                  <CartoucheEvent event={event} />
                </li>
              ))}
            </ul>
            <div className="more">
              <Button color="primary" variant="contained" component={Link} to="/evenements">
                Voir plus
              </Button>
            </div>
          </section>
          <HomeNewslettersSection newsletters={newsletters} />
        </div>
        {homeTag && (
          <Background file={homeData.image} className="highlight">
            <div className="content">
              <h2>{homeData.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: homeData.description,
                }}
              />
              <Button color="primary" component={Link} to={`/tag/${homeTag.id}`}>
                {homeData.buttonText}
              </Button>
            </div>
          </Background>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
