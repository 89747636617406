import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@mui/material';
import { Link } from 'react-router-dom';

import {
  CloseIcon,
  ExpandMoreIcon,
  MenuArborescenceIcon,
  MenuEventsIcon,
  MenuHomeIcon,
  MenuInstancesIcon,
  MenuMetricsIcon,
  MyMarketIcon,
} from '../svg';
import { StrapiMenu } from '../../api/strapi';
import { UserMenu } from './UserMenu';
import { useMobileOrTablet } from '../../hooks';
import { useOnboarding } from '../../providers/OnboardingProvider';

type ContentMenuProps = {
  data: StrapiMenu | undefined;
  open: boolean;
  onClose: () => void;
};

export const ContentMenu = ({ data, open, onClose }: ContentMenuProps) => {
  const mobileOrTablet = useMobileOrTablet();

  const [arborescenceSubmenuOpen, setArborescenceSubmenuOpen] = useState<boolean>(false);
  const [submenuInstancesOpen, setSubmenuInstancesOpen] = useState<boolean>(false);

  const marketTagId = data?.marketTag?.id;
  const hasMarkets = Boolean(marketTagId);
  const hasArborescence = Boolean(data?.arborescence.item.length);
  const hasMetrics = Boolean(data?.hasMetrics);
  const hasInstances =
    data &&
    (data.instanceBureau || data.instanceCommittee || Boolean(data.instanceCommissions.length));

  const { openMenuOnboardingIfNeeded } = useOnboarding();
  useEffect(() => {
    if (open && data) {
      openMenuOnboardingIfNeeded(hasMarkets, hasArborescence, hasMetrics);
    }
  }, [data, hasArborescence, hasMarkets, hasMetrics, open, openMenuOnboardingIfNeeded]);

  if (!data) {
    return null;
  }

  return (
    <Drawer id="menu" anchor="left" open={open} onClose={onClose}>
      <div className="hider">
        <div className="scroll">
          <div className="content">
            <Button className="close" onClick={onClose}>
              {CloseIcon}
            </Button>
            <h6>Menu</h6>
            {mobileOrTablet && <UserMenu />}
            <ul className="contentMenu">
              <li className="homeMenu">
                <Link to="/" onClick={onClose}>
                  {MenuHomeIcon}Accueil
                </Link>
              </li>
              {hasMarkets && (
                <li className="marketsMenu">
                  <Link to={`/tag/${marketTagId}`} onClick={onClose}>
                    {MyMarketIcon}Mes marchés
                  </Link>
                </li>
              )}
              {hasArborescence && (
                <li className="arborescenceMenu">
                  <Link
                    to="/arborescence"
                    onClick={(e) => {
                      setArborescenceSubmenuOpen(!arborescenceSubmenuOpen);
                      e.preventDefault();
                    }}
                  >
                    {MenuArborescenceIcon}Mes adhésions
                  </Link>
                  <Button
                    className={arborescenceSubmenuOpen ? 'toggle open' : 'toggle'}
                    onClick={(e) => {
                      setArborescenceSubmenuOpen(!arborescenceSubmenuOpen);
                      e.preventDefault();
                    }}
                  >
                    {ExpandMoreIcon}
                  </Button>
                  {arborescenceSubmenuOpen && (
                    <ul>
                      <li className="all">
                        <Link to="/arborescence" onClick={onClose}>
                          Voir toutes mes adhésions
                        </Link>
                      </li>
                      {data.arborescence.item.map((item) => (
                        <li key={item.title}>
                          <Link to={`/tag/${item.tag.id}`} onClick={onClose}>
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )}
              {hasMetrics && (
                <li className="indicatorsMenu">
                  <Link to="/indicateurs">{MenuMetricsIcon}Mes indicateurs</Link>
                </li>
              )}
              <li className="eventsMenu">
                <Link to="/evenements" onClick={onClose}>
                  {MenuEventsIcon}Mes évènements
                </Link>
              </li>
              {hasInstances && (
                <li className="instancesMenu">
                  <Button
                    onClick={(e) => {
                      setSubmenuInstancesOpen(!submenuInstancesOpen);
                      e.preventDefault();
                    }}
                  >
                    {MenuInstancesIcon}Mes instances
                  </Button>
                  <Button
                    className={submenuInstancesOpen ? 'toggle open' : 'toggle'}
                    onClick={(e) => {
                      setSubmenuInstancesOpen(!submenuInstancesOpen);
                      e.preventDefault();
                    }}
                  >
                    {ExpandMoreIcon}
                  </Button>
                  {submenuInstancesOpen && (
                    <ul>
                      {data.instanceBureau && (
                        <li>
                          <Link to="/instance/bureaux" onClick={onClose}>
                            {data.instanceBureau.menu}
                          </Link>
                        </li>
                      )}
                      {data.instanceCommittee && (
                        <li>
                          <Link to="/instance/comites" onClick={onClose}>
                            {data.instanceCommittee.menu}
                          </Link>
                        </li>
                      )}
                      {data.instanceCommissions.map((commission) => (
                        <li key={commission.id}>
                          <Link to={`/instance/commission/${commission.id}`} onClick={onClose}>
                            {commission.menu}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
