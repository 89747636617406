import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

import { useDownload, usePageFetch } from '../../hooks';
import { StrapiMetrics, StrapiSection } from '../../api/strapi';
import Error from '../../components/Error';
import { Header } from '../../components/header/Header';
import { DownloadAllIcon } from '../../components/svg';
import { Footer } from '../../components/Footer';
import MetricsSection from './MetricsSection';
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import BackButton from '../../components/BackButton';
import dayjs from 'dayjs';
import { useUser } from '../../providers/UserProvider';

const MetricsPage = () => {
  const { search } = useLocation();
  const { data, error } = usePageFetch<StrapiSection[]>('/api/metric-sections');
  const [selectedSection, setSelectedSection] = useState<StrapiSection>();

  const filteredData = useMemo(
    () =>
      data
        ?.map((section) => {
          const filteredSubsections = section.subsections
            .map((subsection) => ({
              ...subsection,
              metrics: subsection.metrics?.filter((m) => metricsHasValue(m)),
            }))
            .filter((subsection) => subsection.metrics.length > 0);
          return {
            ...section,
            subsections: filteredSubsections,
          };
        })
        .filter((section) => section.subsections.length > 0),
    [data],
  );

  useEffect(() => {
    if (!filteredData?.length) {
      setSelectedSection(undefined);
      return;
    }
    const metricIdParam = new URLSearchParams(search).get('metricId');
    let newSection = filteredData[0];
    if (metricIdParam) {
      const metricId = parseInt(metricIdParam);
      newSection =
        filteredData.find((section) =>
          section.subsections.some((subsection) =>
            subsection.metrics.some((metric) => metric.metric.id === metricId),
          ),
        ) ?? filteredData[0];
      // Scroll to the metric after the next render
      window.setTimeout(() => {
        const scrollTo = window.document.getElementById(`metric-${metricId}`);
        scrollTo?.scrollIntoView();
      });
    }

    setSelectedSection(newSection);
  }, [filteredData, search]);

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      <div id="metricsPage">
        <div id="top">
          <BackButton variant="dark" />
          <div className="content">
            <h1>Mes indicateurs</h1>
            <UpdateSection />
            <PdfButton />
            <div className="tabs">
              <ul>
                {filteredData?.map((section) => (
                  <li key={section.id}>
                    <Button
                      className={clsx(selectedSection?.id === section.id && 'active')}
                      onClick={() => setSelectedSection(section)}
                    >
                      {section.label}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {selectedSection && <MetricsSection section={selectedSection} />}
      </div>
      <Footer />
    </>
  );
};

const metricsHasValue = (metrics: StrapiMetrics) => {
  if (!metrics.metric) {
    return false;
  }
  if (metrics.metric.type === 'boolean' || metrics.metric.type === 'scalar') {
    const value = (metrics.metric.accountMetrics ?? [])[0]?.value;
    return value !== undefined && value !== null;
  } else {
    const valueStr = (metrics.metric.accountMetrics ?? [])[0]?.valueStr;
    return valueStr !== undefined && valueStr !== null && valueStr !== '';
  }
};

const PdfButton = () => {
  const { download, ongoing } = useDownload();
  const { account } = useUser();

  const downloadPdf = async () => {
    await download({
      url: `/api/accounts/${account!.id}/metrics-pdf`,
      name: `Fiche adhérent - ${account!.name}.pdf`,
    });
  };

  return (
    <Button color="primary" className="download" onClick={downloadPdf}>
      {ongoing ? (
        <>{DownloadAllIcon}Téléchargement en cours...</>
      ) : (
        <>{DownloadAllIcon}Ma fiche adhérent</>
      )}
    </Button>
  );
};

// Metrics sync is done at the start of each day so we can just set update date at today.
const UpdateSection = () => {
  const { account } = useUser();
  return (
    <div className="update">
      {account!.name} - Mis à jour le {dayjs().format('DD/MM/YYYY')}
    </div>
  );
};

export default MetricsPage;
