import { GrappAttendanceStatus, GrappPublicationMode } from '../../api/strapi';
import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';

type AttendanceSwitchProps = {
  // No switch if the event is in the past
  past: boolean;
  mode: GrappPublicationMode;
  status: GrappAttendanceStatus | undefined;
  onChange: (status: GrappAttendanceStatus) => void;
};

const AttendanceSwitch = ({ past, status, onChange, mode }: AttendanceSwitchProps) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const changeStatus = async (newStatus: GrappAttendanceStatus) => {
    closeMenu();
    if (newStatus !== status) {
      onChange(newStatus);
    }
  };

  if (past) {
    return <div className="attendance past">Évènement passé</div>;
  }

  const statusOptions = computeAvailableStatuses(status, mode);

  return (
    <div className={classNames('attendance', status)}>
      <Button onClick={openMenu}>{computeLabel(status, mode)}</Button>
      <Menu className="attendanceSwitch" anchorEl={menuAnchor} open={open} onClose={closeMenu}>
        {statusOptions.map((statusOption) => (
          <MenuItem className={statusOption} onClick={() => changeStatus(statusOption)}>
            <button>{computeLabel(statusOption, mode)}</button>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const computeAvailableStatuses = (
  status: GrappAttendanceStatus | undefined,
  mode: GrappPublicationMode,
) => {
  let results: GrappAttendanceStatus[] = [];
  switch (mode) {
    case 'onsite':
      results = ['participate'];
      break;
    case 'remote':
      results = ['participateRemote'];
      break;
    case 'both':
      results = ['participate', 'participateRemote'];
      break;
  }
  if (Boolean(status)) {
    results.push('dontParticipate');
  }
  return results;
};

const computeLabel = (status: GrappAttendanceStatus | undefined, mode: GrappPublicationMode) => {
  switch (status) {
    case 'invited':
      return "Répondre à l'invitation";
    case 'unknown':
      return 'Modifier mon inscription';
    case 'dontParticipate':
      return 'Je ne participe pas';
    case 'participate':
      return mode === 'both' ? 'Je participe en présentiel' : 'Je participe';
    case 'participateRemote':
      return mode === 'both' ? 'Je participe à distance' : 'Je participe';
    default:
      return "M'inscrire";
  }
};

export default AttendanceSwitch;
