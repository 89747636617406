import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';

import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { useDesktop, usePageFetch } from '../../hooks';
import { StrapiEntity, StrapiEvent, StrapiFile, StrapiReferral } from '../../api/strapi';
import Error from '../../components/Error';
import {
  EventsSection,
  ReferralsSection,
  RichTextSection,
  UpdateSection,
} from '../../components/sections';
import { CartoucheDoc } from '../../components/cartouches';
import { computeDates } from '../../utils/events';
import BackButton from '../../components/BackButton';

type InstanceItemAttachment = {
  type: string;
  file: StrapiFile | null;
};

type InstanceItem = {
  id: number;
  date: Dayjs;
  event: StrapiEvent | null;
  attachments: InstanceItemAttachment[];
};

export type Instance = {
  title: string;
  menu: string;
  content: string;
  referrals: StrapiReferral[];
  items: InstanceItem[];
};

type InstancePageProps<T extends StrapiEntity<any>> = {
  itemTitle: string;
  query: string;
  transformResponse: (instance: T) => Instance;
};

const InstancePage = <T extends StrapiEntity<any>>({
  itemTitle,
  query,
  transformResponse,
}: InstancePageProps<T>) => {
  const { search } = useLocation();
  const desktop = useDesktop();

  const { data, error } = usePageFetch<T>(query);

  const instance = useMemo(
    () => (data ? transformResponse(data) : undefined),
    [data, transformResponse],
  );

  const events = useMemo(
    () =>
      instance?.items
        .map((item) => item.event)
        .filter((e) => e)
        .map((e) => e!)
        .filter((e) => computeDates(e).past === false)
        .sort(
          (a, b) => (computeDates(a).start?.unix() ?? 0) - (computeDates(b).start?.unix() ?? 0),
        ),
    [instance],
  );

  const years = useMemo(() => {
    if (!instance) {
      return [];
    }
    const newYears = Array.from(new Set(instance.items.map((item) => item.date.year())));
    newYears.sort((y1, y2) => y2 - y1);
    return newYears;
  }, [instance]);

  const [year, setYear] = useState<number>();
  useEffect(() => {
    const childId = new URLSearchParams(search).get('child');
    if (childId) {
      const itemToShow = instance?.items.filter((item) => item.id === parseInt(childId))[0];
      setYear(itemToShow?.date.year() ?? years[0]);
      window.document.getElementById(`instance-item-${childId}`)?.scrollIntoView();
    } else {
      setYear(years[0]);
    }
  }, [instance, years, search]);

  const filteredItems = useMemo(
    () => instance?.items.filter((item) => !year || item.date.year() === year),
    [instance, year],
  );

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      {data && instance && (
        <div id="instancePage">
          <div id="top">
            <BackButton />
            <div className="content">
              <h1>{instance.title}</h1>
              <UpdateSection entity={data} />
            </div>
          </div>
          <div id="bottom">
            <main>
              {!desktop && (
                <ReferralsSection referrals={instance.referrals} title="Les interlocuteurs" />
              )}
              <RichTextSection html={instance.content} />
              {years.length > 1 && (
                <div className="tabs">
                  <ul>
                    <li className="all">
                      <Button
                        className={clsx(!year && 'active')}
                        onClick={() => setYear(undefined)}
                      >
                        Tous
                      </Button>
                    </li>
                    {years.map((y) => (
                      <li key={y}>
                        <Button className={clsx(y === year && 'active')} onClick={() => setYear(y)}>
                          {y}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {filteredItems!.map((item) => (
                <InstanceSection key={item.id} item={item} itemTitle={itemTitle} />
              ))}
            </main>
            <aside>
              {desktop && (
                <ReferralsSection referrals={instance.referrals} title="Les interlocuteurs" />
              )}
              <EventsSection events={events} />
            </aside>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

type InstanceSectionProps = { item: InstanceItem; itemTitle: string };

const InstanceSection = ({ item, itemTitle }: InstanceSectionProps) => {
  const date = item.date ? dayjs(item.date) : null;
  return (
    <section className="instance" id={`instance-item-${item.id}`}>
      <h2>
        {itemTitle} du {date?.format('DD/MM/YYYY')}
      </h2>
      <ul>
        {item.attachments
          .filter((attachment) => attachment.file)
          .map((attachment) => (
            <li key={attachment.file!.id}>
              <CartoucheDoc file={attachment.file!} name={attachment.type} />
            </li>
          ))}
      </ul>
      {item.event && (
        <Button
          color="primary"
          variant="contained"
          className="event"
          component={Link}
          to={`/evenement/${item.event.id}`}
        >
          Voir l'évènement lié
        </Button>
      )}
    </section>
  );
};

export default InstancePage;
