import { User } from './strapi';

export const formatUser = (user?: User) => {
  if (!user) {
    return '';
  }
  const firstName = user.contact?.firstname;
  const lastName = user.contact?.lastname;
  const fullName = [firstName, lastName].filter((s) => s?.length).join(' ');
  if (fullName.length) {
    return fullName;
  }
  return user.username;
};
