import { CartoucheMetric } from '../../components/cartouches';
import { Button } from '@mui/material';
import React from 'react';
import { StrapiSection } from '../../api/strapi';
import { Link } from 'react-router-dom';

type MetricsSectionProps = {
  section: StrapiSection;
};

const MetricsSection = ({ section }: MetricsSectionProps) => {
  return (
    <div id="bottom" className={section.type}>
      <h2>{section.title}</h2>
      {section.subsections.map(
        (subsection) =>
          subsection.metrics.length > 0 && (
            <section key={subsection.id}>
              <h3>{subsection.label}</h3>
              <ul
                data-size={subsection.metrics.length}
                data-parity={subsection.metrics.length % 2 === 0 ? 'even' : 'odd'}
              >
                {subsection.metrics.map((metric, index) => (
                  <li key={metric.id} id={`metric-${metric.metric.id}`}>
                    <CartoucheMetric
                      metric={metric.metric}
                      type={section.type}
                      highlight={metric.highlight}
                      label={metric.label}
                      mobileFullWidth={index === subsection.metrics.length - 1 && index % 2 === 0}
                    />
                  </li>
                ))}
              </ul>
              <div className="more">
                {subsection.subpageLink && (
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/sous-page/${subsection.subpageLink.id}`}
                  >
                    En savoir plus
                  </Button>
                )}
                {subsection.pageLink && !subsection.subpageLink && (
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/page/${subsection.pageLink.id}`}
                  >
                    En savoir plus
                  </Button>
                )}
                {subsection.tagLink && !subsection.pageLink && !subsection.subpageLink && (
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/tag/${subsection.tagLink.id}`}
                  >
                    En savoir plus
                  </Button>
                )}
              </div>
            </section>
          ),
      )}
    </div>
  );
};

export default MetricsSection;
