import { GrappAccount, GrappAccountRepresentative } from '../../api/strapi';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/Footer';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useUser } from '../../providers/UserProvider';
import Error from '../../components/Error';
import { usePageFetch } from '../../hooks';
import React, { useMemo } from 'react';
import { joinComponents } from '../../utils/utils';
import { Link } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { ENV } from '../../utils/environment';

const isSuppleant = (representative: GrappAccountRepresentative) => {
  // No easy way to order the representatives except by parsing the title
  return representative.title ? representative.title.includes('uppléant') : false;
};

const representativeComparator = (
  left: GrappAccountRepresentative,
  right: GrappAccountRepresentative,
) => {
  const leftSuppleant = isSuppleant(left);
  const rightSuppleant = isSuppleant(right);

  if (leftSuppleant && !rightSuppleant) {
    return 1;
  } else if (!leftSuppleant && rightSuppleant) {
    return -1;
  } else {
    return (left.title ?? '').localeCompare(right.title ?? '');
  }
};

const AccountInformationPage = () => {
  const { account } = useUser();

  const { data, error } = usePageFetch<GrappAccount>(`/api/accounts/${account?.id}`);

  const representatives = useMemo(() => {
    if (!data) {
      return [];
    }
    const reps = [...data.representatives];
    reps.sort(representativeComparator);
    return reps;
  }, [data]);

  if (error) {
    return <Error message={error} />;
  }

  return (
    <>
      <Header />
      {data && (
        <div id="accountPage">
          <div id="top">
            <BackButton />
            <div className="content">
              <h1>{data.name}</h1>
            </div>
          </div>
          <div id="bottom">
            <main>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="label">SIRET</TableCell>
                    <TableCell className="value">{data.siret || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Numéro INSEE</TableCell>
                    <TableCell className="value">{data.insee || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Adresse</TableCell>
                    <TableCell className="value">{data.address || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Site web</TableCell>
                    <TableCell className="value">
                      {(data.website && (
                        <a href={withHttp(data.website)} target="_blank" rel="noreferrer">
                          {data.website}
                        </a>
                      )) ||
                        '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="label">Domaines</TableCell>
                    <TableCell className="value">
                      {data.domains?.map((domain) => domain.value).join(', ') || '-'}
                    </TableCell>
                  </TableRow>
                  {Boolean(data.population) && (
                    <TableRow>
                      <TableCell className="label">Population totale</TableCell>
                      <TableCell className="value">
                        {data.population}
                        <CensusDate date={data.censusDate} />
                      </TableCell>
                    </TableRow>
                  )}
                  {Boolean(data.housingCount) && (
                    <TableRow>
                      <TableCell className="label">Nombre de logements</TableCell>
                      <TableCell className="value">
                        {data.housingCount}
                        <CensusDate date={data.censusDate} />
                      </TableCell>
                    </TableRow>
                  )}
                  {Boolean(data.agentCount) && (
                    <TableRow>
                      <TableCell className="label">Nombre d'agents dans l'organisation</TableCell>
                      <TableCell className="value">{data.agentCount}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className="label">
                      Administrateurs espace adhérents de l'organisation
                    </TableCell>
                    <TableCell className="value">
                      {data?.jobs.length > 0
                        ? joinComponents(
                            data?.jobs.map((job) => {
                              const name = [
                                job.access.user.contact.firstname,
                                job.access.user.contact.lastname,
                              ]
                                .filter((str) => str?.length > 0)
                                .join(' ');

                              return (
                                <a key={job.id} href={`mailto:${job.access.user.email}`}>
                                  {name}
                                </a>
                              );
                            }),
                            ', ',
                          )
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {data.requestCategory && (
                <div className="modify">
                  Les informations ne sont pas à jour ? Vous pouvez faire une demande de
                  modification <Link to={`/contact?category=${data.requestCategory.id}`}>ici</Link>.
                </div>
              )}
            </main>
            {representatives.length > 0 && (
              <aside>
                <section>
                  <h2>Les représentants de votre organisation au {ENV.CLIENT_NAME}</h2>
                  <ul className="list">
                    {representatives.map((rep) => (
                      <li className="item" key={rep.name}>
                        <div className="cartoucheUser">
                          <div className="logo" />
                          <div className="title">{rep.name}</div>
                          <div className="infos">{rep.title}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </section>
              </aside>
            )}
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

const CensusDate = ({ date }: { date: string }) => {
  if (!date) {
    return null;
  }

  return <i>(en date du {new Date(date).toLocaleDateString('fr-FR')})</i>;
};

const withHttp = (website: string) => {
  return website.includes('://') ? website : 'http://' + website;
};

export default AccountInformationPage;
